import React, { useEffect, useState } from 'react'

// Libraries
import { Col, Container, Navbar, Row } from 'react-bootstrap'
import { m } from "framer-motion";
// import { Link } from 'react-router-dom'
import { Link, useParams, Navigate } from 'react-router-dom'
import { SwiperSlide, Swiper } from 'swiper/react';
import { Parallax } from 'react-scroll-parallax'
import { Keyboard } from "swiper";
import { Link as ScrollTo } from "react-scroll"

// Components
import { Header, HeaderCart, HeaderLanguage, HeaderNav, Menu, SearchBar } from "../../../Components/Header/Header";
import Lists from '../../../Components/Lists/Lists'
import Buttons from '../../../Components/Button/Buttons'
import Services from '../../../Components/Services/Services';
import CustomModal from '../../../Components/CustomModal'
// import CustomIconWithText from '../../../Components/CustomIconWithText/CustomIconWithText'
import CustomIconWithText from '../../../Components/CustomIconWithText/CustomIconWithText';
import { fadeIn } from '../../../Functions/GlobalAnimations';
// import PricingTable04 from '../../../Components/PricingTable/PricingTable04';
import PricingTable04 from '../../../Components/PricingTable/PricingTable04';
// import Testimonials from '../../../Components/Testimonials/Testimonials';
import Testimonials from '../../../Components/Testimonials/Testimonials';
import Clients from '../../../Components/Clients/Clients';
// import FooterStyle01 from '../../../Components/Footers/FooterStyle01';
// import Overlap from '../../../Components/Overlap/Overlap';
import Overlap from '../../../Components/Overlap/Overlap';
import SideButtons from "../../../Components/SideButtons";
import FooterStyle03 from '../../../Components/Footers/FooterStyle03'
import PortfolioClassic from "../../../Components/Portfolio/PortfolioClassic";
import IconWithText from '../../../Components/IconWithText/IconWithText'
import TextBox from '../../../Components/TextBox/TextBox';

import Accordions from '../../../Components/Accordion/Accordion';
import Accordionsright from '../../../Components/Accordion/Accordionright';

// Data
import { listDataFaq } from '../../Home/Consulting';
import { serviceData1, serviceData2, serviceData3, serviceData4, serviceData6, serviceData7 } from '../../../Components/Services/ServicesData'
import { TestimonialsData02 } from '../../../Components/Testimonials/TestimonialsData';
import { CustomIconWithTextData2 } from '../../../Components/CustomIconWithText/CustomIconWithTextData';
import { serviceData5 } from '../../../Components/Services/ServicesData';
import { pricingTable04 } from '../../../Components/PricingTable/PricingTableData';
import { pageData1 } from './PageDataPortfolio';



const PageTemplatePortfolio = (props) => {

    const { url } = useParams();

    // Find the data object that matches the URL parameter
    const selectedData = pageData1[url];

    // If no matching data is found, you can handle it accordingly
    if (!selectedData) {
        return <Navigate to="/page/error-404" />;
    }

    // const swiperRef = React.useRef(null)
    return (
        <div style={props.style}>
            {/* Header Start */}
            <Header topSpace={{ desktop: true }} type="reverse-scroll">
                <HeaderNav fluid="fluid" theme="light" bg="white" menu="light" className="px-[35px] py-[0px] md:px-0" containerClass="sm:px-0">
                    <Col className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0">
                        <Link aria-label="header logo" className="flex items-center" to="/">
                            <Navbar.Brand className="inline-block p-0 m-0">
                                <img className="default-logo" width="150" height="50" loading="lazy" src='/assets/img/logo-mob.png' data-rjs='/assets/img/logo-mob.png' alt='logo' />
                                <img className="alt-logo" width="150" height="50" loading="lazy" src='/assets/img/logo-mob.png' data-rjs='/assets/img/logo-mob.png' alt='logo' />
                                <img className="mobile-logo" width="150" height="50" loading="lazy" src='/assets/img/logo-mob.png' data-rjs='/assets/img/logo-mob.png' alt='logo' />
                            </Navbar.Brand>
                        </Link>
                    </Col>
                    <div className="col-auto hidden order-last md:block">
                        <Navbar.Toggle className="md:ml-[10px] sm:ml-0">
                            <span className="navbar-toggler-line"></span>
                            <span className="navbar-toggler-line"></span>
                            <span className="navbar-toggler-line"></span>
                            <span className="navbar-toggler-line"></span>
                        </Navbar.Toggle>
                    </div>
                    <Navbar.Collapse className="col-auto px-0 justify-end">
                        <Menu {...props} />
                    </Navbar.Collapse>
                    <Col className="col-auto text-right pe-0">
                        <SearchBar className="pr-0" />
                        <HeaderLanguage />
                        <HeaderCart />
                    </Col>
                </HeaderNav>
            </Header>
            {/* Header End */}
            <SideButtons />

            {/* Parallax Section Start */}
            <div className="py-[80px] h-auto overflow-hidden md:relative md:py-[40px]">
                <Parallax className="lg-no-parallax absolute top-[0] w-full h-full lg:bg-cover" translateY={[-40, 40]} style={{ backgroundImage: `url(/assets/img/webp/litho-landing-page-bg-img-01.webp)` }}></Parallax>
                <Container className="h-full relative">
                    <Row className="justify-center h-[300px] sm:h-[250px]">
                        <Col xl={6} lg={6} sm={8} className="text-center flex justify-center flex-col font-serif">
                            <h1 className="text-gradient bg-[#ffffff] mb-[15px] inline-block text-xmd leading-[20px]">{selectedData.subTitle}</h1>
                            <h1 className="text-white font-medium -tracking-[1px] mb-0">{selectedData.title}</h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* Parallax Section End */}

            {/* About section Start */}
            <section className="py-[80px] overflow-hidden cover-background lg:py-[100px] md:py-[95px] sm:py-[80px] xs:py-[50px]" style={{ backgroundColor: "#ffffff" }}>
                <Container>
                    <Row className="items-center justify-center">
                        <m.div className="col-lg-6 col-md-10 md:mb-20 consulting-about" {...fadeIn}>
                            <h2 className="heading-4 font-serif font-medium text-darkgray tracking-[-1px] mb-16 w-[80%] lg:w-[90%] md:mb-12 xs:mb-16 xs:w-full">{selectedData.heading} <span className="text-[#0000ff] font-semibold">{selectedData.headingName}</span></h2>
                            <p className="w-[70%] mb-[40px] lg:w-[90%] md:mb-[45px] xs:mb-[25px]">{selectedData.aboutContent}</p>
                            <Lists theme="list-style-02" data={selectedData.listData} />
                            <div className="mt-[50px] md:mt-[25px] xs:mt-[35px] landscape:md:mt-[40px]">
                                <Buttons to="/contact-us" className="mr-[35px] btn-fill btn-fancy rounded-none font-medium font-serif uppercase md:mb-[15px] xs:mb-0" themeColor="#0000ff" color="#fff" size="sm" title="Contact us" />
                                <Buttons to="/our-services" className="font-medium pt-0 font-serif uppercase btn-link after:h-[2px] md:text-md md:mb-[15px] after:bg-darkgray hover:text-blue xs:mb-0" size="xl" color="#0000ff" title="Discover Other Services" />
                            </div>
                        </m.div>
                        <m.div className="col-lg-5 offset-lg-1 col-md-10" {...fadeIn}>
                            <div className="relative">
                                <div className="absolute h-full w-full top-0 left-0 opacity-20"></div>
                                <img className="w-full" alt="" src={selectedData.imgBody} width="500px" height="607.66px" />

                                {/* Modal Component Start */}
                                {/* <CustomModal.Wrapper
                                    className="absolute top-0 items-center flex h-full left-[-50px] md:right-0 md:justify-center md:w-full md:left-0"
                                    modalBtn={<Buttons ariaLabel="modal button" type="submit" className="btn-sonar border-0 shadow-[0_0_15px_rgba(0,0,0,0.1)]" themeColor="#fff" color="#bf8c4c" size="xxl" title={<i className="feather-play text-[35px]" />} />} >
                                    <div className="w-[1020px] max-w-full relative rounded mx-auto">
                                        <div className="fit-video">
                                            <iframe width="100%" height="100%" className="shadow-[0_0_8px_rgba(0,0,0,0.06)]" controls src="https://www.youtube.com/embed/g0f_BRYJLJE?autoplay=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen ></iframe>
                                        </div>
                                    </div>
                                </CustomModal.Wrapper> */}
                                {/* Modal Component End */}
                            </div>
                        </m.div>
                    </Row>
                </Container>
            </section>
            {/* About section End */}


            {/* Section Start */}
            <section className="bg-lightgray py-[100px] lg:py-[90px] md:py-[75px] xs:py-[50px]">
                <Container>
                    <Row className="justify-center flex">
                        <m.div className="text-center mb-20 md:mb-12 col-lg-6 col-sm-8" {...fadeIn}>
                            <span class="text-xmd mb-[15px] font-serif block w-full">{selectedData.centerSmallHeading}</span>
                            <h5 class="font-serif text-darkgray font-medium mb-8 sm:w-full">{selectedData.centerBigHeading}</h5>

                        </m.div>
                        <Col xs={12} md={9} lg={12}>
                            <IconWithText
                                grid="row-cols-1 row-cols-md-1 row-cols-lg-2 gap-y-[15px]"
                                theme="icon-with-text-02 about-us-icon-with-text"
                                data={selectedData.IconWithTextData}
                                animation={fadeIn}
                                animationDelay={0.1}
                            />
                        </Col>
                    </Row>
                </Container>
            </section >
            {/* Section End */}

            {/* Section Start */}
            <m.section className="py-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:pt-[50px] xs:pb-0" {...fadeIn}>
                <Container>
                    <Row className="justify-center">
                        <Col md={12} className="text-center mb-[6%]">
                            <h6 className="font-serif text-darkgray font-medium">{selectedData.techs}</h6>
                        </Col>
                    </Row>
                    <Services grid="row-cols-1 row-cols-lg-3 row-cols-sm-2 gap-y-24 md:gap-y-[4rem] justify-center" theme='service-style-04' className="" data={selectedData.serviceData} animation={fadeIn} />
                </Container>
            </m.section>
            {/* Section End */}

            {/* Section Start */}
            <section className="px-[25px] bg-[#f7f7f7] py-[130px] overflow-hidden lg:py-[90px] sm:py-[70px] sm:pb-0 xs:py-[50px] xs:px-0">
                <Container>
                    <Row className="items-center justify-center">
                        <Col lg={4} sm={8} className="text-center text-lg-start md:mb-[50px]">
                            <h6 className="font-serif w-[80%] text-darkgray font-medium mb-[20px] lg:w-[90%] md:w-full md:mb-[10px]">{selectedData.process}</h6>
                            <Buttons ariaLabel="button" to="/our-services/" className="text- font-medium font-serif uppercase btn-link after:h-[2px] md:text-md after:bg-fastblue hover:opacity-70" size="md" color="#0038e3" title="Discover Services" />
                        </Col>
                        <TextBox
                            grid="row-cols-1 row-cols-md-2 col-lg-8"
                            theme="text-box-style-02 flex-wrap lg:px-0"
                            className="sm:mb-[30px] xs:last:mb-0"
                            data={selectedData.TextBoxData002.slice(0, 6)}
                            animation={fadeIn} />
                    </Row>
                </Container>
            </section>
            {/* Section End */}


            {/* Section Start */}
            <section className="bg-white py-[80px] lg:py-[90px] md:py-[75px] sm:py-[50px]">
                <Container fluid className="px-[12%] xl:px-[10%] lg:px-[3%]">
                    <Row>
                        <Col className="col-12 text-center mb-28">
                            <span className="block font-serif mb-[5px]">Most common questions</span>
                            <h5 className="font-serif text-darkgray font-semibold mb-0">Frequently asked questions</h5>
                        </Col>
                    </Row>
                    <Row className="gap-y-[30px]">
                        {listDataFaq.map((item, i) => {
                            return (
                                <m.div className="col-12 col-lg-4 col-sm-6" key={i} {...fadeIn}>
                                    <div className="faqs-page-list bg-white rounded box-shadow-small p-16 lg:p-12 h-full">
                                        <span className="font-serif text-xmd text-darkgray font-medium block mb-[15px]">{item.title}</span>
                                        <ul className="p-0 list-style text-start mb-[15px]">
                                            {item.content && item.content.map((item, i) => {
                                                return (
                                                    <li key={i}><Link to="/faq" className="hover:text-fastblue">{item}</Link></li>
                                                )
                                            })}
                                        </ul>
                                        <Buttons ariaLabel="Agency info" href="/faq" className="font-medium font-serif uppercase btn-link !tracking-[.5px] after:h-[2px] after:bg-fastblue hover:text-fastblue" size="md" color="#0038e3" title="See all articles" />
                                    </div>
                                </m.div>
                            )
                        })}
                    </Row>
                </Container>
            </section>
            {/* Section End */}
            {/* Footer Start */}
            <FooterStyle03 theme="dark" className="text-[#] bg-[#0b0b26] consulting-footer" />
            {/* Footer End */}
        </div >
    )
}

export default PageTemplatePortfolio