const TabData01 = [
    {
        tabTitle: 'PLANNING',
        subtitle: 'MODERNIZED REDESIGN',
        tabicons: "",
        title: 'Business planning',
        img: 'https://via.placeholder.com/800x625',
        content: 'Lorem ipsum dolor sit amet consectetur adipiscing elit do eiusmod tempor incididunt ut labore et dolore magna ut enim ad minim veniam nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        buttonTitle: "Discover Litho",
        buttonLink: "/"
    },
    {
        tabTitle: 'RESEARCH',
        subtitle: 'INNOVATIVE SOLUTIONS',
        tabicons: "",
        title: 'Business research',
        img: 'https://via.placeholder.com/800x625',
        content: 'Lorem ipsum dolor sit amet consectetur adipiscing elit do eiusmod tempor incididunt ut labore et dolore magna ut enim ad minim veniam nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        buttonTitle: "Discover Litho",
        buttonLink: "/"
    },
    {
        tabTitle: 'TARGET',
        subtitle: 'MODERNIZED REDESIGN',
        tabicons: "",
        title: 'Business target',
        img: 'https://via.placeholder.com/800x625',
        content: 'Lorem ipsum dolor sit amet consectetur adipiscing elit do eiusmod tempor incididunt ut labore et dolore magna ut enim ad minim veniam nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        buttonTitle: "Discover Litho",
        buttonLink: "/"
    },
    {
        tabTitle: 'CAMPAIGN',
        subtitle: 'INNOVATIVE SOLUTIONS',
        tabicons: "",
        title: 'Business campaign',
        img: 'https://via.placeholder.com/800x625',
        content: 'Lorem ipsum dolor sit amet consectetur adipiscing elit do eiusmod tempor incididunt ut labore et dolore magna ut enim ad minim veniam nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        buttonTitle: "Discover Litho",
        buttonLink: "/"
    }
]

const TabData02 = [
    {
        tabTitle: 'Development',
        tabicons: "ti-light-bulb",
        subtitle: 'Transform your ideas. We know that good design means good business.',
        img: 'https://via.placeholder.com/800x550',
        title: 'We are idea driven, working with a strong focus on design and user experience.',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, nostrud exercitation ullamco laboris.',
        buttonTitle: "watch a projects",
        buttonLink: "#"
    },
    {
        tabTitle: 'Performance',
        tabicons: "ti-timer",
        img: 'https://via.placeholder.com/800x550',
        title: 'We are idea driven, working with a strong focus on design and user experience.',
        subtitle: 'Transform your ideas. We know that good design means good business.',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, nostrud exercitation ullamco laboris.',
        buttonTitle: "watch a projects",
        buttonLink: "#"
    },
    {
        tabTitle: 'Analytics',
        tabicons: "ti-pie-chart",
        img: 'https://via.placeholder.com/800x550',
        title: 'We are idea driven, working with a strong focus on design and user experience.',
        subtitle: 'Transform your ideas. We know that good design means good business.',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, nostrud exercitation ullamco laboris.',
        buttonTitle: "watch a projects",
        buttonLink: "#"
    },
    {
        tabTitle: 'Concept',
        tabicons: "ti-crown",
        img: 'https://via.placeholder.com/800x550',
        title: 'We are idea driven, working with a strong focus on design and user experience.',
        subtitle: 'Transform your ideas. We know that good design means good business.',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, nostrud exercitation ullamco laboris.',
        buttonTitle: "watch a projects",
        buttonLink: "#"
    },
]

const TabData03 = [
    {
        tabTitle: 'STARTERS',
        tabicons: "",
        activities: [
            {
                price: '$10.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Green salad',
                content: 'Ipsum is simply dolor text printing',
                author: ''
            },
            {
                price: '$06.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Grilled chicken tenders',
                content: 'Ipsum is simply dolor text printing',
                author: ''
            },
            {
                price: '$10.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Crepes of wrath',
                content: 'Printing and typesetting industry',
                author: '',
                badge: {
                    title: "new",
                    color: "#70a967",
                }
            },
            {
                price: '$06.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Chicken breast burger',
                content: 'Ipsum is simply dolor text printing',
                author: ''
            },
            {
                price: '$08.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Appeteaser blatter',
                content: 'Ipsum is simply dolor text printing',
                author: 'Printing and typesetting industry',

            }, {
                price: '$08.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Spicy mixed olives ',
                content: 'Ipsum is simply dolor text printing',
                author: '',
                badge: {
                    title: "hot",
                    color: "#d74a3a"
                }
            },
            {
                price: '$10.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Spicy mixed olives',
                content: 'Printing and typesetting industry',
                author: ''
            },
            {
                price: '$07.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Appeteaser platter',
                content: 'Ipsum is simply dolor text printing',
                author: ''
            },
        ]
    },
    {
        tabTitle: 'NONVEG',
        tabicons: "",
        activities: [
            {
                price: '$08.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Appeteaser blatter',
                content: 'Lorem ipsum has been the industry',
                author: '',
            },
            {
                price: '$02.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Chicken breast burger',
                content: 'Printing and typesetting industry',
                author: ''
            },
            {
                price: '$04.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Green salad',
                content: 'Ipsum is simply dolor text printing',
                author: ''
            },
            {
                price: '$07.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Grilled chicken tenders',
                content: 'Lorem ipsum has been the industry',
                author: ''
            },
            {
                price: '$08.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Crepes of wrath ',
                content: 'Printing and typesetting industry',
                author: '',
                badge: {
                    title: "new",
                    color: "#70a967",
                }
            },
            {
                price: '$09.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Spicy mixed olives',
                content: 'Ipsum is simply dolor text printing',
                author: '',
                badge: {
                    title: "hot",
                    color: "#d74a3a"
                }
            },
            {
                price: '$02.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Spicy mixed olives',
                content: 'Lorem ipsum has been the industry',
                author: ''
            },
            {
                price: '$03.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Appeteaser platter',
                content: 'Printing and typesetting industry',
                author: ''
            },
        ]
    }, {
        tabTitle: 'VEGETARIAN',
        tabicons: "",
        activities: [
            {
                price: '$10.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Green salad',
                content: 'Ipsum is simply dolor text printing',
                author: ''
            },
            {
                price: '$06.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Grilled chicken tenders',
                content: 'Ipsum is simply dolor text printing',
                author: ''
            },
            {
                price: '$10.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Crepes of wrath',
                content: 'Printing and typesetting industry',
                author: '',
                badge: {
                    title: "new",
                    color: "#70a967"
                }
            },
            {
                price: '$06.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Chicken breast burger',
                content: 'Ipsum is simply dolor text printing',
                author: ''
            },
            {
                price: '$08.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Appeteaser blatter',
                content: 'Ipsum is simply dolor text printing',
                author: 'Printing and typesetting industry'
            }, {
                price: '$08.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Spicy mixed olives ',
                content: 'Ipsum is simply dolor text printing',
                author: '',
                badge: {
                    title: "hot",
                    color: "#d74a3a"
                }
            },
            {
                price: '$10.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Spicy mixed olives',
                content: 'Printing and typesetting industry',
                author: ''
            },
            {
                price: '$07.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Appeteaser platter',
                content: 'Ipsum is simply dolor text printing',
                author: ''
            },
        ]
    }, {
        tabTitle: 'DESSERT',
        tabicons: "",
        activities: [
            {
                price: '$02.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Appeteaser blatter',
                content: 'Lorem ipsum has been the industry',
                author: ''
            },
            {
                price: '$04.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Chicken breast burger',
                content: 'Printing and typesetting industry',
                author: ''
            },
            {
                price: '$06.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Green salad',
                content: 'Ipsum is simply dolor text printing',
                author: ''
            },
            {
                price: '$08.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Grilled chicken tenders',
                content: 'Lorem ipsum has been the industry',
                author: ''
            },
            {
                price: '$03.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Crepes of wrath ',
                content: 'Printing and typesetting industry',
                author: '',
                badge: {
                    title: "new",
                    color: "#70a967"
                }
            },
            {
                price: '$05.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Spicy mixed olives',
                content: 'Ipsum is simply dolor text printing',
                author: '',
                badge: {
                    title: "hot",
                    color: "#d74a3a"
                }
            },
            {
                price: '$08.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Spicy mixed olives',
                content: 'Lorem ipsum has been the industry',
                author: ''
            },
            {
                price: '$03.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Appeteaser platter',
                content: 'Printing and typesetting industry',
                author: ''
            },
        ]
    }, {
        tabTitle: 'DRINKS',
        tabicons: "",
        activities: [
            {
                price: '$10.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Green salad',
                content: 'Ipsum is simply dolor text printing',
                author: ''
            },
            {
                price: '$06.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Grilled chicken tenders',
                content: 'Ipsum is simply dolor text printing',
                author: ''
            },
            {
                price: '$10.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Crepes of wrath',
                content: 'Printing and typesetting industry',
                author: '',
                badge: {
                    title: "new",
                    color: "#70a967"
                }
            },
            {
                price: '$06.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Chicken breast burger',
                content: 'Ipsum is simply dolor text printing',
                author: ''
            },
            {
                price: '$08.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Appeteaser blatter',
                content: 'Ipsum is simply dolor text printing',
                author: 'Printing and typesetting industry'
            }, {
                price: '$08.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Spicy mixed olives ',
                content: 'Ipsum is simply dolor text printing',
                author: '',
                badge: {
                    title: "hot",
                    color: "#d74a3a"
                }
            },
            {
                price: '$10.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Spicy mixed olives',
                content: 'Printing and typesetting industry',
                author: ''
            },
            {
                price: '$07.00',
                img: 'https://via.placeholder.com/73x73',
                title: 'Appeteaser platter',
                content: 'Ipsum is simply dolor text printing',
                author: ''
            },
        ]
    },
]

const TabData05 = [
    {
        tabTitle: 'MONDAY',
        tabicons: "",
        activities: [
            {
                accordionid:"accordion-style-5-1",
                accordionhref:"#accordion-style-5-1",
                time:"06:00 - 07:00",
                title: 'Yoga and pilates',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: 'By Edward watson'
            },
            {
                 accordionid:"accordion-style-5-2",
                accordionhref:"#accordion-style-5-2",
                time:"08:00 - 09:00",
                title: 'Energy blast',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: 'By Bryan jonhson'
            },
            {
                 accordionid:"accordion-style-5-3",
                accordionhref:"#accordion-style-5-3",
                time:"10:00 - 11:00",
                title: 'Cardio workout',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: 'By Jeremy dupont',

            },
            {
                  accordionid:"accordion-style-5-4",
                accordionhref:"#accordion-style-5-4",
                time:"11:00 - 12:00",
                title: 'Athletics classes',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: ' By Edward watson'
            },
            {
                 accordionid:"accordion-style-5-5",
                accordionhref:"#accordion-style-5-5",
                time:"13:00 - 15:00",
                title: 'Boxercise',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: ' By Bryan jonhson',

            }
        ]
    },
    {
        tabTitle: 'TUESDAY',
        tabicons: "",
        activities: [
            {
                accordionid:"accordion-style-5-1",
                accordionhref:"#accordion-style-5-1",
                time:"06:00 - 07:00",
                title: 'Energy blast',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: 'By Edward watson'
            },
            {
                 accordionid:"accordion-style-5-2",
                accordionhref:"#accordion-style-5-2",
                time:"08:00 - 09:00",
                title: 'Yoga and pilates',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: 'By Bryan jonhson'
            },
            {
                 accordionid:"accordion-style-5-3",
                accordionhref:"#accordion-style-5-3",
                time:"10:00 - 11:00",
                title: 'Cardio workout',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: ' By Jeremy dupont'
            },
            {
                  accordionid:"accordion-style-5-4",
                accordionhref:"#accordion-style-5-4",
                time:"12:00 - 13:00",
                title: 'Boxercise',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: 'By Edward watson'
            },
            {
                 accordionid:"accordion-style-5-5",
                accordionhref:"#accordion-style-5-5",
                time:"13:00 - 14:00",
                title: 'Athletics classes',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: 'By Bryan jonhson',

            },

        ]
    },
    {
        tabTitle: 'WEDNESDAY',
        tabicons: "",
        activities: [
            {
                accordionid:"accordion-style-5-1",
                accordionhref:"#accordion-style-5-1",
                time:"06:00 - 07:00",
                title: 'Yoga and pilates',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: 'By Edward watson'
            },
            {
                 accordionid:"accordion-style-5-2",
                accordionhref:"#accordion-style-5-2",
                time:"08:00 - 09:00",
                title: 'Energy blast',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: 'By Bryan jonhson'
            },
            {
                 accordionid:"accordion-style-5-3",
                accordionhref:"#accordion-style-5-3",
                time:"10:00 - 11:00",
                title: 'Cardio workout',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: 'By Jeremy dupont',

            },
            {
                  accordionid:"accordion-style-5-4",
                accordionhref:"#accordion-style-5-4",
                time:"11:00 - 12:00",
                title: 'Athletics classes',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: ' By Edward watson'
            },
            {
                 accordionid:"accordion-style-5-5",
                accordionhref:"#accordion-style-5-5",
                time:"13:00 - 15:00",
                title: 'Boxercise',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: ' By Bryan jonhson',

            }
        ]
    },
    {
        tabTitle: 'THURSDAY',
        tabicons: "",
        activities: [
            {
                accordionid:"accordion-style-5-1",
                accordionhref:"#accordion-style-5-1",
                time:"06:00 - 07:00",
                title: 'Energy blast',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: 'By Edward watson'
            },
            {
                 accordionid:"accordion-style-5-2",
                accordionhref:"#accordion-style-5-2",
                time:"08:00 - 09:00",
                title: 'Yoga and pilates',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: 'By Bryan jonhson'
            },
            {
                 accordionid:"accordion-style-5-3",
                accordionhref:"#accordion-style-5-3",
                time:"10:00 - 11:00",
                title: 'Cardio workout',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: ' By Jeremy dupont'
            },
            {
                  accordionid:"accordion-style-5-4",
                accordionhref:"#accordion-style-5-4",
                time:"12:00 - 13:00",
                title: 'Boxercise',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: 'By Edward watson'
            },
            {
                 accordionid:"accordion-style-5-5",
                accordionhref:"#accordion-style-5-5",
                time:"13:00 - 14:00",
                title: 'Athletics classes',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: 'By Bryan jonhson',

            },

        ]
    },
    {
        tabTitle: 'FRIDAY',
        tabicons: "",
        activities: [
            {
                accordionid:"accordion-style-5-1",
                accordionhref:"#accordion-style-5-1",
                time:"06:00 - 07:00",
                title: 'Yoga and pilates',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: 'By Edward watson'
            },
            {
                accordionid:"accordion-style-5-2",
                accordionhref:"#accordion-style-5-2",
                time:"08:00 - 09:00",
                title: 'Energy blast',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: 'By Bryan jonhson'
            },
            {
                accordionid:"accordion-style-5-3",
                accordionhref:"#accordion-style-5-3",
                time:"10:00 - 11:00",
                title: 'Cardio workout',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: 'By Jeremy dupont',

            },
            {
                accordionid:"accordion-style-5-4",
                accordionhref:"#accordion-style-5-4",
                time:"11:00 - 12:00",
                title: 'Athletics classes',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: ' By Edward watson'
            },
            {
                accordionid:"accordion-style-5-5",
                accordionhref:"#accordion-style-5-5",
                time:"13:00 - 15:00",
                title: 'Boxercise',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: ' By Bryan jonhson',

            }
        ]
    },
    {
        tabTitle: 'SATURDAY',
        tabicons: "",
        activities: [
            {
                accordionid:"accordion-style-5-1",
                accordionhref:"#accordion-style-5-1",
                time:"06:00 - 07:00",
                title: 'Energy blast',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: 'By Edward watson'
            },
            {
                accordionid:"accordion-style-5-2",
                accordionhref:"#accordion-style-5-2",
                time:"08:00 - 09:00",
                title: 'Yoga and pilates',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: 'By Bryan jonhson'
            },
            {
                accordionid:"accordion-style-5-3",
                accordionhref:"#accordion-style-5-3",
                time:"10:00 - 11:00",
                title: 'Cardio workout',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: ' By Jeremy dupont'
            },
            {
                accordionid:"accordion-style-5-4",
                accordionhref:"#accordion-style-5-4",
                time:"12:00 - 13:00",
                title: 'Boxercise',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: 'By Edward watson'
            },
            {
                accordionid:"accordion-style-5-5",
                accordionhref:"#accordion-style-5-5",
                time:"13:00 - 14:00",
                title: 'Athletics classes',
                content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
                author: 'By Bryan jonhson',

            },

        ]
    },
]

const TabData06 = [
    {
        tabTitle: 'MONDAY',
        day: "01 DAY",
        tabicons: "",
        activities: [
            {
                time: '09:00 - 10:00',
                img: 'https://via.placeholder.com/197x197',
                title: 'Cultures of creativity',
                content: 'Lorem ipsum is simply dummy text of the and typesetting industry lorem ipsum.',
                author: 'By Edward Watson'
            },
            {
                time: '10:00 - 12:30',
                img: 'https://via.placeholder.com/197x197',
                title: 'Reinventing experiences',
                content: 'Lorem ipsum is simply dummy text of the and typesetting industry lorem ipsum.',
                author: 'By Edward Watson'
            },
            {

                time: '12:30 - 05:30',
                img: 'https://via.placeholder.com/197x197',
                title: 'Human centered design',
                content: 'Lorem ipsum is simply dummy text of the and typesetting industry lorem ipsum.',
                author: 'By Edward Watson'
            },
        ]
    },
    {
        tabTitle: 'TUESDAY',
        day: "02 DAY",
        tabicons: "",
        activities: [
            {
                time: '09:00 - 10:00',
                img: 'https://via.placeholder.com/197x197',
                title: 'Cultures of creativity',
                content: 'Lorem ipsum is simply dummy text of the and typesetting industry lorem ipsum.',
                author: 'By Edward Watson'
            },
            {
                time: '10:00 - 12:30',
                img: 'https://via.placeholder.com/197x197',
                title: 'Reinventing experiences',
                content: 'Lorem ipsum is simply dummy text of the and typesetting industry lorem ipsum.',
                author: 'By Edward Watson'
            },
            {
                time: '12:30 - 05:30',
                img: 'https://via.placeholder.com/197x197',
                title: 'Human centered design',
                content: 'Lorem ipsum is simply dummy text of the and typesetting industry lorem ipsum.',
                author: 'By Edward Watson'
            },
        ]
    }, {
        tabTitle: 'WEDNESDAY',
        day: "03 DAY",
        tabicons: "",
        activities: [
            {
                time: '09:00 - 10:00',
                img: 'https://via.placeholder.com/197x197',
                title: 'Cultures of creativity',
                content: 'Lorem ipsum is simply dummy text of the and typesetting industry lorem ipsum.',
                author: 'By Edward Watson'
            },
            {
                time: '10:00 - 12:30',
                img: 'https://via.placeholder.com/197x197',
                title: 'Reinventing experiences',
                content: 'Lorem ipsum is simply dummy text of the and typesetting industry lorem ipsum.',
                author: 'By Edward Watson'
            },
            {
                time: '12:30 - 05:30',
                img: 'https://via.placeholder.com/197x197',
                title: 'Human centered design',
                content: 'Lorem ipsum is simply dummy text of the and typesetting industry lorem ipsum.',
                author: 'By Edward Watson'
            },
        ]
    }, {
        tabTitle: 'THURSDAY',
        day: "04 DAY",
        tabicons: "",
        activities: [
            {
                time: '09:00 - 10:00',
                img: 'https://via.placeholder.com/197x197',
                title: 'Cultures of creativity',
                content: 'Lorem ipsum is simply dummy text of the and typesetting industry lorem ipsum.',
                author: 'By Edward Watson'
            },
            {
                time: '10:00 - 12:30',
                img: 'https://via.placeholder.com/197x197',
                title: 'Reinventing experiences',
                content: 'Lorem ipsum is simply dummy text of the and typesetting industry lorem ipsum.',
                author: 'By Edward Watson'
            },
            {
                time: '12:30 - 05:30',
                img: 'https://via.placeholder.com/197x197',
                title: 'Human centered design',
                content: 'Lorem ipsum is simply dummy text of the and typesetting industry lorem ipsum.',
                author: 'By Edward Watson'
            },
        ]
    }, {
        tabTitle: 'FRIDAY',
        day: "05 DAY",
        tabicons: "",
        activities: [
            {
                time: '09:00 - 10:00',
                img: 'https://via.placeholder.com/197x197',
                title: 'Cultures of creativity',
                content: 'Lorem ipsum is simply dummy text of the and typesetting industry lorem ipsum.',
                author: 'By Edward Watson'
            },
            {
                time: '10:00 - 12:30',
                img: 'https://via.placeholder.com/197x197',
                title: 'Reinventing experiences',
                content: 'Lorem ipsum is simply dummy text of the and typesetting industry lorem ipsum.',
                author: 'By Edward Watson'
            },
            {
                time: '12:30 - 05:30',
                img: 'https://via.placeholder.com/197x197',
                title: 'Human centered design',
                content: 'Lorem ipsum is simply dummy text of the and typesetting industry lorem ipsum.',
                author: 'By Edward Watson'
            },
        ]
    },
]

const TabData07 = [
    {
        tabTitle: 'eCommerce Solutions',
        featurebox: [
          {
            icon: "line-icon-Cart text-[#4285f4] text-[50px]",
            title: "Tailored eCommerce Solutions",
            content: "Delivering custom eCommerce solutions designed to optimize online businesses and enhance customer experiences."
          },
          {
            icon: "line-icon-Light-Bulb text-[#4285f4] text-[50px]",
            title: "Innovative Features",
            content: "Integrating innovative features to keep your online store competitive and user-friendly."
          }
        ],
        title: 'eCommerce Solutions',
        // subtitle: "Excel in online retail with our tailored eCommerce solutions and innovative features.",
        img: "/assets/img/webp/ecom1.webp",
        buttonTitle: "Discover eCommerce",
        buttonLink: "/page/ecommerce-solutions",
        tabicons: "",
      },
      {
        tabTitle: 'Learning Management System (LMS)',
        featurebox: [
          {
            icon: "line-icon-Book text-[#4285f4] text-[50px]",
            title: "Custom LMS Solutions",
            content: "Crafting custom Learning Management System solutions to facilitate seamless e-learning experiences."
          },
          {
            icon: "line-icon-Brain text-[#4285f4] text-[50px]",
            title: "Effective Learning Strategies",
            content: "Implementing effective learning strategies to maximize knowledge retention and engagement."
          }
        ],
        title: 'Learning Management System (LMS)',
        // subtitle: "Empower education and training with our custom LMS solutions and effective learning strategies.",
        img: "/assets/img/webp/lms1.webp",
        buttonTitle: "Discover LMS",
        buttonLink: "/page/lms-solutions",
        tabicons: "",
      },
      {
        tabTitle: 'Hospital Management System (HMS)',
        featurebox: [
          {
            icon: "line-icon-Hospital text-[#4285f4] text-[50px]",
            title: "Integrated HMS Solutions",
            content: "Providing integrated Hospital Management System solutions for efficient healthcare operations."
          },
          {
            icon: "line-icon-Heart text-[#4285f4] text-[50px]",
            title: "Patient-Centric Approach",
            content: "Focusing on a patient-centric approach to enhance the overall healthcare experience."
          }
        ],
        title: 'Hospital Management System (HMS)',
        // subtitle: "Optimize healthcare operations with our integrated HMS solutions and patient-centric approach.",
        img: "/assets/img/webp/hms1.webp",
        buttonTitle: "Discover HMS",
        buttonLink: "/page/hms-solutions",
        tabicons: "",
      },
      {
        tabTitle: 'Enterprise Resource Planning (ERP)',
        featurebox: [
          {
            icon: "line-icon-Database text-[#4285f4] text-[50px]",
            title: "Comprehensive ERP Solutions",
            content: "Offering comprehensive Enterprise Resource Planning solutions for streamlined business processes."
          },
          {
            icon: "line-icon-Organization text-[#4285f4] text-[50px]",
            title: "Optimized Business Operations",
            content: "Optimizing business operations and increasing efficiency through our ERP solutions."
          }
        ],
        title: 'Enterprise Resource Planning (ERP)',
        // subtitle: "Enhance business efficiency with our comprehensive ERP solutions and optimized business operations.",
        img: "/assets/img/webp/erp1.webp",
        buttonTitle: "Discover ERP",
        buttonLink: "/page/erp-solutions",
        tabicons: "",
      }
]

export { TabData01, TabData02, TabData03, TabData05, TabData06, TabData07 }