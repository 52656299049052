/** Custom web application development services */

export const pageData1 = {
     "custom-web-application-development-services": { //url
          title: "Custom Web Application Development Services",
          subTitle: "Tailored Solutions for Your Business",
          heading: "Crafting Exceptional Web and Mobile Applications",
          headingName: "HasoTech",
          aboutContent: "Elevate your business with our dedicated custom web application development services. HasoTech is committed to delivering exceptional user experiences through cutting-edge technologies tailored for diverse applications. Our team brings together a group of talented and experienced developers specializing in crafting innovative solutions designed for web and mobile platforms.",
          centerBigHeading: "Empower Your Online Presence with Custom Web Development",
          centerSmallHeading: "Unleash the full potential of branding and user engagement with our custom web application development services for web and mobile platforms.",
          techs: "Cutting-Edge Technologies for Web and Mobile App Development",
          process: "Streamlined Process of Crafting Custom Web and Mobile Applications",
          imgBody: "/assets/img/webp/bann.webp",
          listData: [
               {
                    icon: "fas fa-check",
                    content: "Collaborate with skilled custom web application developers to create powerful and tailored solutions using cutting-edge technologies. We specialize in crafting innovative web applications designed to meet the unique needs of your business."
               },
               {
                    icon: "fas fa-check",
                    content: "Outsource your custom web application development to build feature-rich and responsive applications. Ensure a seamless user experience with the expertise of a dedicated outsourcing team, committed to bringing your unique vision to life."
               },
               {
                    icon: "fas fa-check",
                    content: "Leverage the expertise of our seasoned custom web application developers. Choose an outsourcing provider that will work to create well-structured and scalable web applications, precisely tailored to the specific requirements of your business."
               }
          ],
          TextBoxData002: [
               {
                    number: "01",
                    title: "Understand Web Application Requirements",
                    description: "Partnering with a web application development service provider streamlines the development process. We collaborate to gather comprehensive requirements, understanding the design, client's specifications, cost estimation, and project timeline efficiently."
               },
               {
                    number: "02",
                    title: "Select the Optimal Framework for Web Applications",
                    description: "Choosing the right technology stack is crucial for web application development. Our development experts meticulously select the best framework tailored to your web application requirements, ensuring optimal performance and scalability."
               },
               {
                    number: "03",
                    title: "Create Mock UI for Web Application",
                    description: "Outsourcing web application development brings expertise in creating a mock UI that visualizes the application's functionality. This ensures a seamless and user-friendly experience, aligning with the latest industry trends."
               },
               {
                    number: "04",
                    title: "Implement Web Application APIs or Direct Integration",
                    description: "Outsourced development ensures expertise in establishing a standardized architecture for creating a Web Service API. We leverage RESTful APIs or direct implementation, ensuring smooth communication between different components of your web application system."
               },
               {
                    number: "05",
                    title: "Develop the Web Application Platform",
                    description: "Collaborating with a web application development partner accelerates the platform's development. Our expert development team brings the web application to life, aligning with your specific requirements and prioritizing creative designs and user-centric features."
               },
               {
                    number: "06",
                    title: "Conduct Automation Testing for Web Applications",
                    description: "Outsourcing web application development ensures the quality and reliability of your software. We provide comprehensive automation testing services, facilitating faster release cycles and improving overall efficiency for your application, leading to a robust and error-free solution."
               }
          ],
          IconWithTextData: [
               {
                    icon: "line-icon-Code text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    title: "Custom Software Development",
                    content: "Partner with us for custom software development tailored to your unique business needs. Our team excels in creating efficient and scalable solutions, ensuring optimal performance and user satisfaction.",
               },
               {
                    icon: "line-icon-Layout-Grid2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    title: "Versatile IT Solutions",
                    content: "Elevate your business with our versatile IT solutions. We specialize in providing a range of services to address your specific requirements, offering flexibility and effective solutions for your business.",
               },
               {
                    icon: "line-icon-Cogs text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    title: "Comprehensive Integration Services",
                    content: "Our services include seamless integration with 3rd party systems. We provide scalable and high-performance solutions tailored to your business needs through comprehensive integration services.",
               },
               {
                    icon: "line-icon-Tools text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    title: "Maintenance and Support",
                    content: "Rely on us for secure maintenance and timely support for your IT systems. Stay ahead of the market with our dedicated deployment, maintenance, and support services, ensuring the smooth operation of your systems.",
               }
          ],
          serviceData: [
               {
                    img: "/assets/img/webp/reactjs.webp",
                    title: "React Framework"
               },
               {
                    img: "/assets/img/webp/nodejs.webp",
                    title: "NodeJs"
               },
               {
                    img: "/assets/img/webp/mysql.webp",
                    title: "MySQL"
               },
               {
                    img: "/assets/img/webp/mongo.webp",
                    title: "MongoDB",
               },
               {
                    img: "/assets/img/webp/angularr.webp",
                    title: "Angular"
               },
               {
                    img: "/assets/img/webp/aws.webp",
                    title: "Amazon Web Services (AWS)"
               },
          ]

     },

     /** Social media marketing */

     "social-media-marketing": { //url
          title: "Ignite Your Brand on Social Media",
          subTitle: "Social Media Marketing Solutions",
          heading: "Strategic Social Media Marketing Services for Brand Amplification",
          headingName: "HasoTechnology",
          aboutContent: "Boost your brand's visibility and engagement with our specialized social media marketing solutions. HasoTechnology is dedicated to creating impactful strategies and campaigns that resonate with your target audience. Our team comprises skilled professionals who excel in leveraging the power of social media platforms to elevate your brand presence and drive meaningful connections with your audience.",
          centerBigHeading: "Amplify Your Presence with Social Media Marketing",
          centerSmallHeading: "Maximize brand exposure and customer engagement through our tailored social media marketing services.",
          techs: "Cutting-Edge Strategies for Social Media Success",
          process: "Our Proven Process for Effective Social Media Marketing Campaigns",
          imgBody: "/assets/img/webp/dm.webp",
          listData: [
               {
                    icon: "fas fa-check",
                    content: "Collaborate with experienced social media marketing specialists to enhance your online presence. Leverage our expertise to craft engaging campaigns that resonate with your target audience across various platforms."
               },
               {
                    icon: "fas fa-check",
                    content: "Outsource your social media marketing efforts to create compelling and responsive content. Ensure a seamless and interactive experience for your audience with the support of a dedicated outsourcing team."
               },
               {
                    icon: "fas fa-check",
                    content: "Tap into the skills of our seasoned social media experts by choosing a specialized provider. They will work to create well-structured and scalable social media campaigns tailored to the specific needs and goals of your business."
               }
          ],
          TextBoxData002: [
               {
                    number: "01",
                    title: "Understand Social Media Marketing Goals",
                    description: "Partnering with a social media marketing service provider begins with understanding your goals. We collaborate to comprehensively grasp your marketing objectives, identifying target audiences, specifying campaign requirements, estimating costs, and establishing project timelines efficiently."
               },
               {
                    number: "02",
                    title: "Select the Optimal Platforms and Strategies",
                    description: "Choosing the right social media platforms and strategies is crucial for effective marketing. Our social media marketing experts meticulously select the best platforms and strategies tailored to your marketing goals, ensuring optimal reach and engagement with your target audience."
               },
               {
                    number: "03",
                    title: "Create Engaging Content and Visuals",
                    description: "Outsourcing social media marketing brings expertise in creating engaging content and visuals that resonate with your audience. This ensures a compelling and user-friendly experience, aligning with the latest trends in social media marketing."
               },
               {
                    number: "04",
                    title: "Implement Social Media Campaigns and Integration",
                    description: "Outsourced development ensures expertise in establishing effective social media campaigns. We leverage targeted ads, sponsored content, and strategic integration, ensuring seamless communication and engagement across different social media channels."
               },
               {
                    number: "05",
                    title: "Execute the Social Media Marketing Plan",
                    description: "Collaborating with a social media marketing partner accelerates the execution of your marketing plan. Our expert team brings the plan to life, aligning with your specific requirements and prioritizing creative campaigns and user-centric content for optimal social media presence."
               },
               {
                    number: "06",
                    title: "Conduct Analytics and Optimization",
                    description: "Outsourcing social media marketing ensures the effectiveness of your campaigns. We provide comprehensive analytics and optimization services, facilitating data-driven decisions and improving overall efficiency for your social media marketing efforts, leading to a robust and successful social media strategy."
               }

          ],
          IconWithTextData: [
               {
                    icon: "line-icon-Bullhorn text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    title: "Social Media Campaigns",
                    content: "Amplify your brand's voice with our targeted social media campaigns. We craft engaging strategies to reach your audience, boost brand awareness, and drive results across various platforms.",
               },
               {
                    icon: "line-icon-Group text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    title: "Community Building",
                    content: "Foster a vibrant online community through our community building services. We leverage social media platforms to create connections, drive engagement, and strengthen your brand presence.",
               },
               {
                    icon: "line-icon-Chat text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    title: "Social Media Listening",
                    content: "Gain valuable insights with our social media listening services. We monitor conversations, track trends, and analyze user feedback to inform your marketing strategy and enhance brand perception.",
               },
               {
                    icon: "line-icon text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    title: "Targeted Ad Campaigns",
                    content: "Maximize your reach with targeted ad campaigns. Our experts create and optimize social media ads to reach specific demographics, ensuring your message resonates with the right audience.",
               }
          ],
          serviceData: [
               {
                    img: "/assets/img/webp/gsc.webp",
                    title: "Google Search Console"
               },
               {
                    img: "/assets/img/webp/seo.webp",
                    title: "SEO"
               },
               {
                    img: "/assets/img/webp/mysql.webp",
                    title: "MySQL"
               },
               {
                    img: "/assets/img/webp/mongo.webp",
                    title: "MongoDB",
               },
               {
                    img: "/assets/img/webp/msazure.webp",
                    title: "Azure"
               },
               {
                    img: "/assets/img/webp/aws.webp",
                    title: "Amazon Web Services (AWS)"
               },
          ]

     },

     /** Software testing in toronto */

     "software-testing-in-toronto": { //url
          title: "Comprehensive Software Testing Services",
          subTitle: "Ensuring Reliability and Performance",
          heading: "Ensuring Quality through Rigorous Software Testing By",
          headingName: "HasoTech",
          aboutContent: "Enhance the reliability of your software with our comprehensive software testing services. HasoTech is dedicated to delivering exceptional quality assurance through cutting-edge testing methodologies tailored for various applications. Our team consists of skilled and experienced testers specializing in ensuring the reliability and performance of software solutions.",
          centerBigHeading: "Ensure Your Software's Excellence with Comprehensive Testing",
          centerSmallHeading: "Unleash the full potential of your software with our software testing services. We prioritize thorough testing to guarantee optimal performance and user satisfaction.",
          techs: "Advanced Testing Technologies for Software Development",
          process: "Streamlined Process of Rigorous Software Testing for Enhanced Reliability",
          imgBody: "/assets/img/webp/consult1.webp",
          listData: [
               {
                    icon: "fas fa-check",
                    content: "Collaborate with skilled software testing professionals to ensure the reliability and performance of your applications. We specialize in implementing cutting-edge testing methodologies to craft robust and error-free software solutions tailored to your business needs."
               },
               {
                    icon: "fas fa-check",
                    content: "Outsource your software testing services to guarantee comprehensive quality assurance. Ensure a seamless user experience with the expertise of a dedicated outsourcing team committed to identifying and resolving potential issues in your software."
               },
               {
                    icon: "fas fa-check",
                    content: "Leverage the expertise of our seasoned software testers. Choose an outsourcing provider that will work to create well-structured and scalable testing strategies, precisely tailored to the specific requirements of your software development process."
               }
          ],
          TextBoxData002: [
               {
                    number: "01",
                    title: "Understand Software Testing Requirements",
                    description: "Partnering with a software testing service provider streamlines the testing process. We collaborate to gather comprehensive testing requirements, understanding the design, specifications, cost estimation, and project timeline efficiently."
               },
               {
                    number: "02",
                    title: "Select the Optimal Testing Framework for Applications",
                    description: "Choosing the right testing framework is crucial for software testing services. Our testing experts meticulously select the best framework tailored to your application testing requirements, ensuring optimal performance and scalability."
               },
               {
                    number: "03",
                    title: "Create Mock UI for Application Testing",
                    description: "Outsourcing software testing services brings expertise in creating a mock UI for application testing that visualizes the functionality. This ensures a seamless and user-friendly testing experience, aligning with the latest industry trends."
               },
               {
                    number: "04",
                    title: "Implement Application Testing APIs or Direct Integration",
                    description: "Outsourced development ensures expertise in establishing a standardized architecture for creating testing APIs. We leverage RESTful APIs or direct implementation, ensuring smooth communication between different components of your application testing system."
               },
               {
                    number: "05",
                    title: "Conduct Comprehensive Automation Testing for Applications",
                    description: "Collaborating with a software testing service provider accelerates the testing process. Our expert testing team conducts comprehensive automation testing services, facilitating faster release cycles and improving overall efficiency for your applications, leading to a robust and error-free solution."
               }
          ],
          IconWithTextData: [
               {
                    icon: "line-icon-Code text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    title: "Comprehensive Software Testing",
                    content: "Collaborate with us for comprehensive software testing services tailored to ensure the quality and reliability of your applications. Our team excels in implementing efficient and scalable testing solutions, guaranteeing optimal performance and user satisfaction."
               },
               {
                    icon: "line-icon-Layout-Grid2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    title: "Adaptable QA Solutions",
                    content: "Elevate your business with our adaptable QA solutions. We specialize in providing a range of software testing services to address your specific requirements, offering flexibility and effective solutions for ensuring the quality of your applications."
               },
               {
                    icon: "line-icon-Cogs text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    title: "Integration Testing Excellence",
                    content: "Our services include seamless integration testing with 3rd party systems. We provide scalable and high-performance solutions tailored to your business needs through comprehensive integration testing services, ensuring the seamless operation of your systems."
               },
               {
                    icon: "line-icon-Tools text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    title: "Maintenance and Support Testing",
                    content: "Rely on us for secure maintenance and timely support testing for your IT systems. Stay ahead of the market with our dedicated deployment, maintenance, and support testing services, ensuring the smooth operation and continuous improvement of your systems."
               }
          ],
          serviceData: [
               {
                    img: "/assets/img/webp/reactjs.webp",
                    title: "React Framework"
               },
               {
                    img: "/assets/img/webp/nodejs.webp",
                    title: "NodeJs"
               },
               {
                    img: "/assets/img/webp/mysql.webp",
                    title: "MySQL"
               },
               {
                    img: "/assets/img/webp/mongo.webp",
                    title: "MongoDB",
               },
               {
                    img: "/assets/img/webp/angularr.webp",
                    title: "Angular"
               },
               {
                    img: "/assets/img/webp/aws.webp",
                    title: "Amazon Web Services (AWS)"
               },
          ]

     },


     /** App Development companies in Canada */

     "app-development-companies-in-canada": { //url
          title: "App Development Services in Canada",
          subTitle: "Ensuring Reliability and Performance for Your Applications",
          heading: "Ensuring Quality through Robust App Development By",
          headingName: "HasoTechnology",
          aboutContent: "Enhance the reliability of your applications with our comprehensive app development services. CanAppTech is dedicated to delivering exceptional quality through cutting-edge development methodologies tailored for various app requirements. Our team consists of skilled and experienced developers specializing in ensuring the reliability and performance of app solutions.",
          centerBigHeading: "Ensure Your App's Excellence with Comprehensive Development",
          centerSmallHeading: "Unleash the full potential of your applications with our app development services. We prioritize thorough development to guarantee optimal performance and user satisfaction.",
          techs: "Advanced Technologies for App Development",
          process: "Streamlined Process of Robust App Development for Enhanced Reliability",
          imgBody: "/assets/img/webp/consult.webp",
          listData: [
               {
                    "icon": "fas fa-check",
                    "content": "Collaborate with skilled app development professionals to ensure the reliability and performance of your applications. We specialize in implementing cutting-edge development methodologies to craft robust and error-free app solutions tailored to your business needs."
               },
               {
                    "icon": "fas fa-check",
                    "content": "Outsource your app development services to guarantee comprehensive quality assurance. Ensure a seamless user experience with the expertise of a dedicated outsourcing team committed to identifying and resolving potential issues in your app."
               },
               {
                    "icon": "fas fa-check",
                    "content": "Leverage the expertise of our seasoned app developers. Choose an outsourcing provider that will work to create well-structured and scalable development strategies, precisely tailored to the specific requirements of your app development process."
               }
          ],
          TextBoxData002: [
               {
                    number: "01",
                    title: "Understand Web Application Requirements",
                    description: "Partnering with a web application development service provider streamlines the development process. We collaborate to gather comprehensive requirements, understanding the design, client's specifications, cost estimation, and project timeline efficiently."
               },
               {
                    number: "02",
                    title: "Select the Optimal Framework for Web Applications",
                    description: "Choosing the right technology stack is crucial for web application development. Our development experts meticulously select the best framework tailored to your web application requirements, ensuring optimal performance and scalability."
               },
               {
                    number: "03",
                    title: "Create Mock UI for Web Application",
                    description: "Outsourcing web application development brings expertise in creating a mock UI that visualizes the application's functionality. This ensures a seamless and user-friendly experience, aligning with the latest industry trends."
               },
               {
                    number: "04",
                    title: "Implement Web Application APIs or Direct Integration",
                    description: "Outsourced development ensures expertise in establishing a standardized architecture for creating a Web Service API. We leverage RESTful APIs or direct implementation, ensuring smooth communication between different components of your web application system."
               },
               {
                    number: "05",
                    title: "Develop the Web Application Platform",
                    description: "Collaborating with a web application development partner accelerates the platform's development. Our expert development team brings the web application to life, aligning with your specific requirements and prioritizing creative designs and user-centric features."
               },
               {
                    number: "06",
                    title: "Conduct Automation Testing for Web Applications",
                    description: "Outsourcing web application development ensures the quality and reliability of your software. We provide comprehensive automation testing services, facilitating faster release cycles and improving overall efficiency for your application, leading to a robust and error-free solution."
               }
          ],
          IconWithTextData: [
               {
                    "icon": "line-icon-Code text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Comprehensive App Development",
                    "content": "Collaborate with us for comprehensive app development services tailored to ensure the quality and reliability of your applications. Our team excels in implementing efficient and scalable development solutions, guaranteeing optimal performance and user satisfaction."
               },
               {
                    "icon": "line-icon-Layout-Grid2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Adaptable App Development Solutions",
                    "content": "Elevate your business with our adaptable app development solutions. We specialize in providing a range of app development services to address your specific requirements, offering flexibility and effective solutions for ensuring the quality of your applications."
               },
               {
                    "icon": "line-icon-Cogs text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Integration Excellence for Apps",
                    "content": "Our services include seamless integration testing with 3rd party systems. We provide scalable and high-performance solutions tailored to your business needs through comprehensive integration services, ensuring the seamless operation of your app systems."
               },
               {
                    "icon": "line-icon-Tools text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Maintenance and Support for App Development",
                    "content": "Rely on us for secure maintenance and timely support for your app systems. Stay ahead of the market with our dedicated deployment, maintenance, and support services, ensuring the smooth operation and continuous improvement of your applications."
               }
          ],
          serviceData: [
               {
                    img: "/assets/img/webp/reactjs.webp",
                    title: "React Framework"
               },
               {
                    img: "/assets/img/webp/nodejs.webp",
                    title: "NodeJs"
               },
               {
                    img: "/assets/img/webp/mysql.webp",
                    title: "MySQL"
               },
               {
                    img: "/assets/img/webp/mongo.webp",
                    title: "MongoDB",
               },
               {
                    img: "/assets/img/webp/angularr.webp",
                    title: "Angular"
               },
               {
                    img: "/assets/img/webp/aws.webp",
                    title: "Amazon Web Services (AWS)"
               },
          ]

     },

     /** software-as-a-service-application-development */

     "software-as-a-service-application-development": { //url
          title: "SaaS Development Services",
          subTitle: "Ensuring Reliability and Performance for Your SaaS Solutions",
          heading: "Ensuring Quality through Robust SaaS Development By",
          headingName: "HasoTechnology",
          aboutContent: "Elevate the reliability of your SaaS applications with our comprehensive SaaS development services. HasoTechnology is dedicated to delivering exceptional quality through cutting-edge development methodologies tailored for various SaaS requirements. Our team consists of skilled and experienced developers specializing in ensuring the reliability and performance of SaaS solutions.",
          centerBigHeading: "Ensure Your SaaS Excellence with Comprehensive Development",
          centerSmallHeading: "Unleash the full potential of your SaaS solutions with our SaaS development services. We prioritize thorough development to guarantee optimal performance and user satisfaction.",
          techs: "Advanced Technologies for SaaS Development",
          process: "Streamlined Process of Robust SaaS Development for Enhanced Reliability in the Canadian Market",
          imgBody: "/assets/img/webp/dev.webp",
          listData: [
               {
                    "icon": "fas fa-check",
                    "content": "Collaborate with skilled SaaS development professionals to ensure the reliability and performance of your SaaS applications. We specialize in implementing cutting-edge development methodologies to craft robust and error-free SaaS solutions tailored to your business needs."
               },
               {
                    "icon": "fas fa-check",
                    "content": "Outsource your SaaS development services to guarantee comprehensive quality assurance. Ensure a seamless user experience with the expertise of a dedicated outsourcing team committed to identifying and resolving potential issues in your SaaS application."
               },
               {
                    "icon": "fas fa-check",
                    "content": "Leverage the expertise of our seasoned SaaS developers. Choose an outsourcing provider that will work to create well-structured and scalable development strategies, precisely tailored to the specific requirements of your SaaS development process."
               }
          ],
          TextBoxData002: [
               {
                    number: "01",
                    title: "Understand Web Application Requirements",
                    description: "Partnering with a web application development service provider streamlines the development process. We collaborate to gather comprehensive requirements, understanding the design, client's specifications, cost estimation, and project timeline efficiently."
               },
               {
                    number: "02",
                    title: "Select the Optimal Framework for Web Applications",
                    description: "Choosing the right technology stack is crucial for web application development. Our development experts meticulously select the best framework tailored to your web application requirements, ensuring optimal performance and scalability."
               },
               {
                    number: "03",
                    title: "Create Mock UI for Web Application",
                    description: "Outsourcing web application development brings expertise in creating a mock UI that visualizes the application's functionality. This ensures a seamless and user-friendly experience, aligning with the latest industry trends."
               },
               {
                    number: "04",
                    title: "Implement Web Application APIs or Direct Integration",
                    description: "Outsourced development ensures expertise in establishing a standardized architecture for creating a Web Service API. We leverage RESTful APIs or direct implementation, ensuring smooth communication between different components of your web application system."
               },
               {
                    number: "05",
                    title: "Develop the Web Application Platform",
                    description: "Collaborating with a web application development partner accelerates the platform's development. Our expert development team brings the web application to life, aligning with your specific requirements and prioritizing creative designs and user-centric features."
               },
               {
                    number: "06",
                    title: "Conduct Automation Testing for Web Applications",
                    description: "Outsourcing web application development ensures the quality and reliability of your software. We provide comprehensive automation testing services, facilitating faster release cycles and improving overall efficiency for your application, leading to a robust and error-free solution."
               }
          ],
          IconWithTextData: [
               {
                    "icon": "line-icon-Code text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Comprehensive App Development",
                    "content": "Collaborate with us for comprehensive app development services tailored to ensure the quality and reliability of your applications. Our team excels in implementing efficient and scalable development solutions, guaranteeing optimal performance and user satisfaction."
               },
               {
                    "icon": "line-icon-Layout-Grid2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Adaptable App Development Solutions",
                    "content": "Elevate your business with our adaptable app development solutions. We specialize in providing a range of app development services to address your specific requirements, offering flexibility and effective solutions for ensuring the quality of your applications."
               },
               {
                    "icon": "line-icon-Cogs text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Integration Excellence for Apps",
                    "content": "Our services include seamless integration testing with 3rd party systems. We provide scalable and high-performance solutions tailored to your business needs through comprehensive integration services, ensuring the seamless operation of your app systems."
               },
               {
                    "icon": "line-icon-Tools text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Maintenance and Support for App Development",
                    "content": "Rely on us for secure maintenance and timely support for your app systems. Stay ahead of the market with our dedicated deployment, maintenance, and support services, ensuring the smooth operation and continuous improvement of your applications."
               }
          ],
          serviceData: [
               {
                    img: "/assets/img/webp/reactjs.webp",
                    title: "React Framework"
               },
               {
                    img: "/assets/img/webp/nodejs.webp",
                    title: "NodeJs"
               },
               {
                    img: "/assets/img/webp/mysql.webp",
                    title: "MySQL"
               },
               {
                    img: "/assets/img/webp/mongo.webp",
                    title: "MongoDB",
               },
               {
                    img: "/assets/img/webp/angularr.webp",
                    title: "Angular"
               },
               {
                    img: "/assets/img/webp/aws.webp",
                    title: "Amazon Web Services (AWS)"
               },
          ]

     },

     /** ecommerce-integration-with-lazada-shopee */

     "ecommerce-integration-with-lazada-shopee": { //url
          title: "Ecommerce Integration With Lazada & Shopee",
          subTitle: "Ensuring Reliability and Performance for Your Ecommerce Solutions",
          heading: "Ensuring Quality through Robust Seller Central Development By",
          headingName: "HasoTechnology",
          aboutContent: "Elevate the reliability of your Seller Central application with our comprehensive development services. HasoTechnology is dedicated to delivering exceptional quality through cutting-edge development methodologies tailored for various ecommerce requirements. Our team consists of skilled and experienced developers specializing in ensuring the reliability and performance of Seller Central solutions.",
          centerBigHeading: "Ensure Your Seller Central Excellence with Comprehensive Development",
          centerSmallHeading: "Unleash the full potential of your ecommerce solutions with our Seller Central development services. We prioritize thorough development to guarantee optimal performance and user satisfaction.",
          techs: "Advanced Technologies for Seller Central Development",
          process: "Streamlined Process of Robust Seller Central Development for Enhanced Reliability in Ecommerce Markets",
          imgBody: "/assets/img/webp/dev.webp",
          listData: [
               {
                    "icon": "fas fa-check",
                    "content": "Collaborate with skilled Seller Central development professionals to ensure the reliability and performance of your centralized selling platform. We specialize in implementing cutting-edge development methodologies to craft robust and error-free solutions tailored to the unique needs of your ecommerce business."
               },
               {
                    "icon": "fas fa-check",
                    "content": "Outsource your Seller Central development services to guarantee comprehensive quality assurance. Ensure a seamless user experience with the expertise of a dedicated outsourcing team committed to identifying and resolving potential issues in your Seller Central application integrated with Lazada, Shopee, Magento, and Shopify."
               },
               {
                    "icon": "fas fa-check",
                    "content": "Leverage the expertise of our seasoned Seller Central developers. Choose an outsourcing provider that will work to create well-structured and scalable development strategies, precisely tailored to the specific requirements of your Seller Central application integrating Lazada, Shopee, Magento, and Shopify."
               }
          ],
          TextBoxData002: [
               {
                    "number": "01",
                    "title": "Understand Seller Central Application Requirements",
                    "description": "Partnering with a seller central application development service provider streamlines the process. We collaborate to gather comprehensive requirements, understanding the design, client's specifications, cost estimation, and project timeline efficiently for product management on Lazada, Shopee, Shopify, and Magento."
               },
               {
                    "number": "02",
                    "title": "Select the Optimal Framework for Seller Central Applications",
                    "description": "Choosing the right technology stack is crucial for seller central application development. Our development experts meticulously select the best framework tailored to your seller central application requirements, ensuring optimal performance and scalability on platforms like Lazada, Shopee, Shopify, and Magento."
               },
               {
                    "number": "03",
                    "title": "Create Mock UI for Seller Central Application",
                    "description": "Outsourcing seller central application development brings expertise in creating a mock UI that visualizes the application's functionality. This ensures a seamless and user-friendly experience, aligning with the latest industry trends for product management on Lazada, Shopee, Shopify, and Magento."
               },
               {
                    "number": "04",
                    "title": "Implement APIs for Seller Central Integration",
                    "description": "Outsourced development ensures expertise in establishing a standardized architecture for creating a Web Service API. We leverage RESTful APIs or direct implementation, ensuring smooth communication between different components of your seller central application system for seamless integration with Lazada, Shopee, Shopify, and Magento."
               },
               {
                    "number": "05",
                    "title": "Develop the Seller Central Application Platform",
                    "description": "Collaborating with a seller central application development partner accelerates the platform's development. Our expert development team brings the seller central application to life, aligning with your specific requirements and prioritizing creative designs and user-centric features for product management on Lazada, Shopee, Shopify, and Magento."
               },
               {
                    "number": "06",
                    "title": "Conduct Automation Testing for Seller Central Applications",
                    "description": "Outsourcing seller central application development ensures the quality and reliability of your software. We provide comprehensive automation testing services, facilitating faster release cycles and improving overall efficiency for your seller central application, leading to a robust and error-free solution with seamless order fetching from Lazada, Shopee, Shopify, and Magento."
               }
          ],
          IconWithTextData: [
               {
                    "icon": "line-icon-Code text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Comprehensive Seller Central Development",
                    "content": "Collaborate with us for comprehensive Seller Central development services tailored to ensure the quality and reliability of your centralized selling platform. Our team excels in implementing efficient and scalable development solutions, guaranteeing optimal performance and user satisfaction."
               },
               {
                    "icon": "line-icon-Layout-Grid2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Adaptable Seller Central Solutions",
                    "content": "Elevate your business with our adaptable Seller Central development solutions. We specialize in providing a range of services for Seller Central applications to address your specific requirements, offering flexibility and effective solutions for ensuring the quality of your centralized selling platform."
               },
               {
                    "icon": "line-icon-Cogs text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Integration Excellence for Seller Central Apps",
                    "content": "Our services include seamless integration testing with 3rd party systems for Seller Central applications. We provide scalable and high-performance solutions tailored to your business needs through comprehensive integration services, ensuring the seamless operation of your Seller Central systems."
               },
               {
                    "icon": "line-icon-Tools text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Maintenance and Support for Seller Central Development",
                    "content": "Rely on us for secure maintenance and timely support for your Seller Central systems. Stay ahead of the market with our dedicated deployment, maintenance, and support services, ensuring the smooth operation and continuous improvement of your Seller Central applications."
               }
          ],
          serviceData: [
               {
                    img: "/assets/img/webp/reactjs.webp",
                    title: "React Framework"
               },
               {
                    img: "/assets/img/webp/nodejs.webp",
                    title: "NodeJs"
               },
               {
                    img: "/assets/img/webp/mysql.webp",
                    title: "MySQL"
               },
               {
                    img: "/assets/img/webp/mongo.webp",
                    title: "MongoDB",
               },
               {
                    img: "/assets/img/webp/angularr.webp",
                    title: "Angular"
               },
               {
                    img: "/assets/img/webp/aws.webp",
                    title: "Amazon Web Services (AWS)"
               },
          ]

     },

     /** point-of-scale-application-development */

     "point-of-scale-application-development": { //url
          title: "Point Of Sale Application Development",
          subTitle: "Ensuring Reliability and Performance for Your Point of Sale Solutions",
          heading: "Ensuring Quality through Robust POS Development By",
          headingName: "HasoTechnology",
          aboutContent: "Elevate the reliability of your Point of Sale (POS) application with our comprehensive development services. HasoTechnology is dedicated to delivering exceptional quality through cutting-edge development methodologies tailored for various retail and POS requirements. Our team consists of skilled and experienced developers specializing in ensuring the reliability and performance of Point of Sale solutions.",
          centerBigHeading: "Ensure Your POS Excellence with Comprehensive Development",
          centerSmallHeading: "Unleash the full potential of your retail solutions with our Point of Sale development services. We prioritize thorough development to guarantee optimal performance and user satisfaction.",
          techs: "Advanced Technologies for POS Development",
          process: "Streamlined Process of Robust POS Development for Enhanced Reliability in Retail Markets",
          imgBody: "/assets/img/webp/dev.webp",
          listData: [
               {
                    "icon": "fas fa-check",
                    "content": "Collaborate with skilled Seller Central development professionals to ensure the reliability and performance of your centralized selling platform. We specialize in implementing cutting-edge development methodologies to craft robust and error-free solutions tailored to the unique needs of your ecommerce business."
               },
               {
                    "icon": "fas fa-check",
                    "content": "Outsource your Seller Central development services to guarantee comprehensive quality assurance. Ensure a seamless user experience with the expertise of a dedicated outsourcing team committed to identifying and resolving potential issues in your Seller Central application integrated with Lazada, Shopee, Magento, and Shopify."
               },
               {
                    "icon": "fas fa-check",
                    "content": "Leverage the expertise of our seasoned Seller Central developers. Choose an outsourcing provider that will work to create well-structured and scalable development strategies, precisely tailored to the specific requirements of your Seller Central application integrating Lazada, Shopee, Magento, and Shopify."
               }
          ],
          TextBoxData002: [
               {
                    "number": "01",
                    "title": "Understand Seller Central Application Requirements",
                    "description": "Partnering with a seller central application development service provider streamlines the process. We collaborate to gather comprehensive requirements, understanding the design, client's specifications, cost estimation, and project timeline efficiently for product management on Lazada, Shopee, Shopify, and Magento."
               },
               {
                    "number": "02",
                    "title": "Select the Optimal Framework for Seller Central Applications",
                    "description": "Choosing the right technology stack is crucial for seller central application development. Our development experts meticulously select the best framework tailored to your seller central application requirements, ensuring optimal performance and scalability on platforms like Lazada, Shopee, Shopify, and Magento."
               },
               {
                    "number": "03",
                    "title": "Create Mock UI for Seller Central Application",
                    "description": "Outsourcing seller central application development brings expertise in creating a mock UI that visualizes the application's functionality. This ensures a seamless and user-friendly experience, aligning with the latest industry trends for product management on Lazada, Shopee, Shopify, and Magento."
               },
               {
                    "number": "04",
                    "title": "Implement APIs for Seller Central Integration",
                    "description": "Outsourced development ensures expertise in establishing a standardized architecture for creating a Web Service API. We leverage RESTful APIs or direct implementation, ensuring smooth communication between different components of your seller central application system for seamless integration with Lazada, Shopee, Shopify, and Magento."
               },
               {
                    "number": "05",
                    "title": "Develop the Seller Central Application Platform",
                    "description": "Collaborating with a seller central application development partner accelerates the platform's development. Our expert development team brings the seller central application to life, aligning with your specific requirements and prioritizing creative designs and user-centric features for product management on Lazada, Shopee, Shopify, and Magento."
               },
               {
                    "number": "06",
                    "title": "Conduct Automation Testing for Seller Central Applications",
                    "description": "Outsourcing seller central application development ensures the quality and reliability of your software. We provide comprehensive automation testing services, facilitating faster release cycles and improving overall efficiency for your seller central application, leading to a robust and error-free solution with seamless order fetching from Lazada, Shopee, Shopify, and Magento."
               }
          ],
          IconWithTextData: [
               {
                    "icon": "line-icon-Code text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Comprehensive Seller Central Development",
                    "content": "Collaborate with us for comprehensive Seller Central development services tailored to ensure the quality and reliability of your centralized selling platform. Our team excels in implementing efficient and scalable development solutions, guaranteeing optimal performance and user satisfaction."
               },
               {
                    "icon": "line-icon-Layout-Grid2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Adaptable Seller Central Solutions",
                    "content": "Elevate your business with our adaptable Seller Central development solutions. We specialize in providing a range of services for Seller Central applications to address your specific requirements, offering flexibility and effective solutions for ensuring the quality of your centralized selling platform."
               },
               {
                    "icon": "line-icon-Cogs text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Integration Excellence for Seller Central Apps",
                    "content": "Our services include seamless integration testing with 3rd party systems for Seller Central applications. We provide scalable and high-performance solutions tailored to your business needs through comprehensive integration services, ensuring the seamless operation of your Seller Central systems."
               },
               {
                    "icon": "line-icon-Tools text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Maintenance and Support for Seller Central Development",
                    "content": "Rely on us for secure maintenance and timely support for your Seller Central systems. Stay ahead of the market with our dedicated deployment, maintenance, and support services, ensuring the smooth operation and continuous improvement of your Seller Central applications."
               }
          ],
          serviceData: [
               {
                    img: "/assets/img/webp/reactjs.webp",
                    title: "React Framework"
               },
               {
                    img: "/assets/img/webp/nodejs.webp",
                    title: "NodeJs"
               },
               {
                    img: "/assets/img/webp/mysql.webp",
                    title: "MySQL"
               },
               {
                    img: "/assets/img/webp/mongo.webp",
                    title: "MongoDB",
               },
               {
                    img: "/assets/img/webp/angularr.webp",
                    title: "Angular"
               },
               {
                    img: "/assets/img/webp/aws.webp",
                    title: "Amazon Web Services (AWS)"
               },
          ]

     },

     /** php-laravel-application-development */

     "php-laravel-application-development": { //url
          title: "Php Laravel Application Development",
          subTitle: "Empowering Your Business with Reliable and High-Performance PHP Laravel Applications",
          heading: "Crafting Excellence in POS Solutions with",
          headingName: "HasoTechnology",
          aboutContent: "Elevate your business with our top-notch PHP Laravel application development services. HasoTechnology is committed to delivering outstanding quality through cutting-edge development methodologies tailored for various retail and POS requirements. Our team comprises skilled and experienced developers specializing in ensuring the reliability and performance of PHP Laravel-based Point of Sale solutions.",
          centerBigHeading: "Enhance Your Business with Comprehensive PHP Laravel Development",
          centerSmallHeading: "Unlock the full potential of your retail solutions with our PHP Laravel application development services. We prioritize meticulous development to guarantee optimal performance and user satisfaction.",
          techs: "Utilizing Advanced Technologies for PHP Laravel Development",
          process: "Efficient Process of Robust PHP Laravel Development for Enhanced Reliability in Retail Markets",
          imgBody: "/assets/img/webp/laravel.webp",
          listData: [
               {
                    "icon": "fas fa-check",
                    "content": "Collaborate with skilled PHP Laravel development professionals to ensure the reliability and performance of your centralized selling platform. We specialize in implementing cutting-edge Laravel development methodologies to craft robust and error-free solutions tailored to the unique needs of your ecommerce business."
               },
               {
                    "icon": "fas fa-check",
                    "content": "Outsource your PHP Laravel development services to guarantee comprehensive quality assurance. Ensure a seamless user experience with the expertise of a dedicated outsourcing team committed to identifying and resolving potential issues in your PHP Laravel-based Seller Central application integrated with Lazada, Shopee, Magento, and Shopify."
               },
               {
                    "icon": "fas fa-check",
                    "content": "Leverage the expertise of our seasoned PHP Laravel developers. Choose an outsourcing provider that will work to create well-structured and scalable development strategies, precisely tailored to the specific requirements of your PHP Laravel-based Seller Central application integrating Lazada, Shopee, Magento, and Shopify."
               }
          ],
          TextBoxData002: [
               {
                    "number": "01",
                    "title": "Understand PHP Laravel Application Requirements",
                    "description": "Partnering with a PHP Laravel application development service provider streamlines the process. We collaborate to gather comprehensive requirements, understanding the design, client's specifications, cost estimation, and project timeline efficiently for product management on Lazada, Shopee, Shopify, and Magento."
               },
               {
                    "number": "02",
                    "title": "Select the Optimal Framework for PHP Laravel Applications",
                    "description": "Choosing the right technology stack is crucial for PHP Laravel application development. Our development experts meticulously select the best framework tailored to your PHP Laravel application requirements, ensuring optimal performance and scalability on platforms like Lazada, Shopee, Shopify, and Magento."
               },
               {
                    "number": "03",
                    "title": "Create Mock UI for PHP Laravel Application",
                    "description": "Outsourcing PHP Laravel application development brings expertise in creating a mock UI that visualizes the application's functionality. This ensures a seamless and user-friendly experience, aligning with the latest industry trends for product management on Lazada, Shopee, Shopify, and Magento."
               },
               {
                    "number": "04",
                    "title": "Implement APIs for PHP Laravel Application Integration",
                    "description": "Outsourced PHP Laravel development ensures expertise in establishing a standardized architecture for creating a Web Service API. We leverage RESTful APIs or direct implementation, ensuring smooth communication between different components of your PHP Laravel application system for seamless integration with Lazada, Shopee, Shopify, and Magento."
               },
               {
                    "number": "05",
                    "title": "Develop the PHP Laravel Application Platform",
                    "description": "Collaborating with a PHP Laravel application development partner accelerates the platform's development. Our expert PHP Laravel development team brings the application to life, aligning with your specific requirements and prioritizing creative designs and user-centric features for product management on Lazada, Shopee, Shopify, and Magento."
               },
               {
                    "number": "06",
                    "title": "Conduct Automation Testing for PHP Laravel Applications",
                    "description": "Outsourcing PHP Laravel application development ensures the quality and reliability of your software. We provide comprehensive automation testing services, facilitating faster release cycles and improving overall efficiency for your PHP Laravel application, leading to a robust and error-free solution with seamless order fetching from Lazada, Shopee, Shopify, and Magento."
               }
          ],
          IconWithTextData: [
               {
                    "icon": "line-icon-Code text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Comprehensive PHP Laravel Development",
                    "content": "Collaborate with us for comprehensive PHP Laravel development services tailored to ensure the quality and reliability of your centralized selling platform. Our team excels in implementing efficient and scalable PHP Laravel solutions, guaranteeing optimal performance and user satisfaction."
               },
               {
                    "icon": "line-icon-Layout-Grid2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Adaptable PHP Laravel Solutions",
                    "content": "Elevate your business with our adaptable PHP Laravel development solutions. We specialize in providing a range of services for PHP Laravel applications to address your specific requirements, offering flexibility and effective solutions for ensuring the quality of your centralized selling platform."
               },
               {
                    "icon": "line-icon-Cogs text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Integration Excellence for PHP Laravel Apps",
                    "content": "Our services include seamless integration testing with 3rd party systems for PHP Laravel applications. We provide scalable and high-performance solutions tailored to your business needs through comprehensive integration services, ensuring the seamless operation of your PHP Laravel systems."
               },
               {
                    "icon": "line-icon-Tools text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Maintenance and Support for PHP Laravel Development",
                    "content": "Rely on us for secure maintenance and timely support for your PHP Laravel systems. Stay ahead of the market with our dedicated deployment, maintenance, and support services, ensuring the smooth operation and continuous improvement of your PHP Laravel applications."
               }
          ],
          serviceData: [
               {
                    img: "/assets/img/webp/lara.webp",
                    title: "Laravel Framework"
               },
               {
                    img: "/assets/img/webp/nodejs.webp",
                    title: "NodeJs"
               },
               {
                    img: "/assets/img/webp/mysql.webp",
                    title: "MySQL"
               },
               {
                    img: "/assets/img/webp/mongo.webp",
                    title: "MongoDB",
               },
               {
                    img: "/assets/img/webp/php.webp",
                    title: "PHP"
               },
               {
                    img: "/assets/img/webp/aws.webp",
                    title: "Amazon Web Services (AWS)"
               },
          ]

     },


     /** ui-ux-design-and-development-services */

     "ui-ux-design-and-development-services": { //url
          title: "UI/UX Design & Development Services",
          subTitle: "Revitalize Your Business with Intuitive UI/UX Design",
          heading: "Crafting Excellence in UI/UX Solutions with",
          headingName: "HasoTechnology",
          aboutContent: "Elevate your business with our top-notch UI/UX design. HasoTechnology is committed to delivering outstanding quality through cutting-edge UI/UX design and development methodologies tailored for various retail and POS requirements. Our team comprises skilled and experienced UI/UX designers and developers specializing in creating visually stunning and user-friendly experiences.",
          centerBigHeading: "Enhance Your Business with Comprehensive UI/UX Development",
          centerSmallHeading: "Unlock the full potential of your retail solutions with our UI/UX development and PHP Laravel services. We prioritize meticulous UI/UX design and development to guarantee optimal user experience, performance, and customer satisfaction.",
          techs: "Utilizing Advanced UI/UX Technologies.",
          process: "Efficient Process of Robust UI/UX Design for Enhanced User Satisfaction in Retail Markets",
          imgBody: "/assets/img/webp/ui.webp",
          listData: [
               {
                    "icon": "fas fa-check",
                    "content": "Collaborate with skilled UI/UX design and development professionals to ensure the aesthetics, usability, and performance of your centralized selling platform. We specialize in implementing cutting-edge UI/UX design methodologies to craft visually appealing, user-friendly, and error-free solutions tailored to the unique needs."
               },
               {
                    "icon": "fas fa-check",
                    "content": "Outsource your UI/UX design and development services to guarantee comprehensive quality assurance. Ensure a seamless and delightful user experience with the expertise of a dedicated outsourcing team committed to identifying and resolving potential UI/UX issues in your application."
               },
               {
                    "icon": "fas fa-check",
                    "content": "Leverage the expertise of our seasoned UI/UX designers and developers. Choose an outsourcing provider that will work to create well-structured and scalable UI/UX design and development strategies, precisely tailored to the specific requirements."
               }
          ],
          TextBoxData002: [
               {
                    "number": "01",
                    "title": "Understand UI/UX Design Requirements for Applications",
                    "description": "Partnering with a UI/UX development service provider streamlines the process. We collaborate to gather comprehensive UI/UX design requirements, understanding the visual elements, user experience goals, client's specifications, cost estimation, and project timeline efficiently for product management across various platforms."
               },
               {
                    "number": "02",
                    "title": "Select the Optimal UI/UX Framework for Applications",
                    "description": "Choosing the right UI/UX technology stack is crucial for application development. Our UI/UX development experts meticulously select the best framework tailored to your application requirements, ensuring optimal user experience, performance, and scalability on various platforms."
               },
               {
                    "number": "03",
                    "title": "Create Mock UI/UX for Applications",
                    "description": "Outsourcing UI/UX application development brings expertise in creating a mock UI that visualizes the application's design and functionality. This ensures a seamless and visually appealing user experience, aligning with the latest UI/UX design trends for product management across different platforms."
               },
               {
                    "number": "04",
                    "title": "Implement UI/UX Design for Application Integration",
                    "description": "Outsourced UI/UX development ensures expertise in establishing a standardized UI/UX architecture for creating a Web Service API. We leverage user-centric UI/UX design principles, ensuring smooth communication between different components of your application system for seamless integration with various platforms."
               },
               {
                    "number": "05",
                    "title": "Develop the UI/UX Design for Application Platform",
                    "description": "Collaborating with a UI/UX development partner accelerates the UI/UX design of the application platform. Our expert UI/UX development team brings the application to life, aligning with your specific UI/UX requirements and prioritizing creative designs for product management across different platforms."
               },
               {
                    "number": "06",
                    "title": "Conduct UI/UX Testing for Applications",
                    "description": "Outsourcing UI/UX development ensures the quality and reliability of your software's user interface and experience. We provide comprehensive UI/UX testing services, facilitating faster release cycles and improving overall efficiency for your application, leading to a visually appealing, user-friendly, and error-free solution with seamless order fetching from various platforms."
               }

          ],
          IconWithTextData: [
               {
                    "icon": "line-icon-Code text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Comprehensive UI/UX Design and Application Development",
                    "content": "Collaborate with us for comprehensive UI/UX design and application development services tailored to ensure the quality and reliability of your centralized selling platform. Our team excels in implementing efficient and scalable UI/UX and application solutions, guaranteeing optimal performance and user satisfaction."
               },
               {
                    "icon": "line-icon-Layout-Grid2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Adaptable UI/UX and Application Solutions",
                    "content": "Elevate your business with our adaptable UI/UX design and application development solutions. We specialize in providing a range of services for UI/UX and applications to address your specific requirements, offering flexibility and effective solutions for ensuring the quality of your centralized selling platform."
               },
               {
                    "icon": "line-icon-Cogs text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Integration Excellence for UI/UX and Applications",
                    "content": "Our services include seamless integration testing with 3rd party systems for UI/UX and applications. We provide scalable and high-performance solutions tailored to your business needs through comprehensive integration services, ensuring the seamless operation of your UI/UX and application systems."
               },
               {
                    "icon": "line-icon-Tools text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Maintenance and Support for UI/UX and Application Development",
                    "content": "Rely on us for secure maintenance and timely support for your UI/UX and application systems. Stay ahead of the market with our dedicated deployment, maintenance, and support services, ensuring the smooth operation and continuous improvement of your UI/UX and application applications."
               }
          ],
          serviceData: [
               {
                    img: "/assets/img/webp/lara.webp",
                    title: "Laravel Framework"
               },
               {
                    img: "/assets/img/webp/nodejs.webp",
                    title: "NodeJs"
               },
               {
                    img: "/assets/img/webp/mysql.webp",
                    title: "MySQL"
               },
               {
                    img: "/assets/img/webp/mongo.webp",
                    title: "MongoDB",
               },
               {
                    img: "/assets/img/webp/php.webp",
                    title: "PHP"
               },
               {
                    img: "/assets/img/webp/aws.webp",
                    title: "Amazon Web Services (AWS)"
               },
          ]

     },

     /** MERN Stack */

     "react-development-company": { //url
          title: "MERN Stack Development Services",
          subTitle: "Transform Your Web Applications with MERN Stack Excellence",
          heading: "Crafting Excellence in MERN Stack Solutions with",
          headingName: "HasoTechnology",
          aboutContent: "Elevate your business with our top-notch MERN stack development. HasoTechnology is committed to delivering outstanding quality through cutting-edge MERN stack development methodologies tailored for various web application requirements. Our team comprises skilled and experienced MERN stack developers specializing in creating robust and user-friendly web experiences.",
          centerBigHeading: "Enhance Your Business with Comprehensive MERN Stack Development",
          centerSmallHeading: "Unlock the full potential of your web solutions with our MERN stack development services. We prioritize meticulous development to guarantee optimal user experience, performance, and customer satisfaction.",
          techs: "Utilizing Advanced MERN Stack Technologies.",
          process: "Efficient Process of Robust MERN Stack Development for Enhanced User Satisfaction in Web Applications Markets",
          imgBody: "/assets/img/webp/reactjs.webp",
          listData: [
               {
                    "icon": "fas fa-check",
                    "content": "Collaborate with skilled MERN stack development professionals for aesthetically pleasing, user-friendly, and high-performance web applications. Our cutting-edge methodologies cater to your unique needs, ensuring a centralized selling platform that stands out."
               },
               {
                    "icon": "fas fa-check",
                    "content": "Outsource your MERN stack development services for comprehensive quality assurance. Our dedicated team identifies and resolves potential issues, ensuring a seamless and delightful user experience in your web application."
               },
               {
                    "icon": "fas fa-check",
                    "content": "Leverage the expertise of our seasoned MERN stack developers. Choose an outsourcing provider committed to creating well-structured and scalable strategies precisely tailored to the specific requirements of your web application."
               }
          ],
          TextBoxData002: [
               {
                    "number": "01",
                    "title": "Define MERN Stack Requirements",
                    "description": "Collaborate with our MERN stack development experts to gather comprehensive requirements, understanding visual elements, user experience goals, specifications, cost estimation, and project timeline efficiently for streamlined product management."
               },
               {
                    "number": "02",
                    "title": "Select Optimal MERN Stack Framework",
                    "description": "Our MERN stack development team meticulously selects the best framework tailored to your web application, ensuring optimal user experience, performance, and scalability on various platforms."
               },
               {
                    "number": "03",
                    "title": "Create Mock UI for MERN Stack",
                    "description": "Outsourcing MERN stack development brings expertise in creating a mock UI that visualizes the web application's design and functionality, ensuring a seamless and visually appealing user experience aligned with the latest trends."
               },
               {
                    "number": "04",
                    "title": "Implement MERN Stack Integration",
                    "description": "Outsourced MERN stack development ensures expertise in establishing a standardized architecture, leveraging user-centric design principles for seamless communication between different components of your web application on various platforms."
               },
               {
                    "number": "05",
                    "title": "Develop MERN Stack for Web Applications",
                    "description": "Collaborate with our MERN stack development team to accelerate the design of your web application platform, aligning with specific requirements and prioritizing creative designs for streamlined product management."
               },
               {
                    "number": "06",
                    "title": "Conduct MERN Stack Testing",
                    "description": "Outsourcing MERN stack development ensures quality and reliability through comprehensive testing services, facilitating faster release cycles and improving overall efficiency for your web application, leading to a visually appealing, user-friendly, and error-free solution."
               }

          ],
          IconWithTextData: [
               {
                    "icon": "line-icon-Code text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Comprehensive MERN Stack Development Services",
                    "content": "Collaborate with us for comprehensive MERN stack development services tailored to ensure the quality and reliability of your web application. Our team excels in implementing efficient and scalable MERN stack solutions, guaranteeing optimal performance and user satisfaction."
               },
               {
                    "icon": "line-icon-Layout-Grid2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Adaptable MERN Stack Solutions",
                    "content": "Elevate your business with our adaptable MERN stack development solutions. We specialize in providing a range of services for MERN stack development to address your specific requirements, offering flexibility and effective solutions for ensuring the quality of your web application."
               },
               {
                    "icon": "line-icon-Cogs text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Integration Excellence for MERN Stack Development",
                    "content": "Our services include seamless integration testing with 3rd party systems for MERN stack development. We provide scalable and high-performance solutions tailored to your business needs through comprehensive integration services, ensuring the seamless operation of your MERN stack application systems."
               },
               {
                    "icon": "line-icon-Tools text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Maintenance and Support for MERN Stack Development",
                    "content": "Rely on us for secure maintenance and timely support for your MERN stack application systems. Stay ahead of the market with our dedicated deployment, maintenance, and support services, ensuring the smooth operation and continuous improvement of your MERN stack applications."
               }
          ],
          serviceData: [
               {
                    img: "/assets/img/webp/reactjs.webp",
                    title: "React Framework"
               },
               {
                    img: "/assets/img/webp/nodejs.webp",
                    title: "NodeJs"
               },
               {
                    img: "/assets/img/webp/mysql.webp",
                    title: "MySQL"
               },
               {
                    img: "/assets/img/webp/mongo.webp",
                    title: "MongoDB",
               },
               {
                    img: "/assets/img/webp/msazure.webp",
                    title: "Azure"
               },
               {
                    img: "/assets/img/webp/aws.webp",
                    title: "Amazon Web Services (AWS)"
               },
          ]

     },



     /** search-engine-optimization */

     "search-engine-optimization": { //url
          title: "SEO Services",
          subTitle: "Boost Your Online Presence with SEO Excellence",
          heading: "Crafting Excellence in SEO Solutions with",
          headingName: "HasoTechnology",
          aboutContent: "Elevate your business with our top-notch SEO services. HasoTechnology is committed to delivering outstanding quality through cutting-edge SEO methodologies tailored for various online marketing requirements. Our team comprises skilled and experienced SEO specialists specializing in improving search visibility and online performance.",
          centerBigHeading: "Enhance Your Business with Comprehensive SEO Services",
          centerSmallHeading: "Unlock the full potential of your online presence with our SEO services. We prioritize meticulous optimization to guarantee optimal search visibility, website performance, and customer satisfaction.",
          techs: "Utilizing Advanced SEO Technologies.",
          process: "Efficient Process of Robust SEO Services for Enhanced User Satisfaction in Online Markets",
          imgBody: "/assets/img/webp/seo.webp",
          listData: [
               {
                    "icon": "fas fa-check",
                    "content": "Collaborate with skilled SEO professionals for search engine optimized and user-friendly web applications. Our cutting-edge methodologies cater to your unique needs, ensuring a website that stands out in search results and provides an excellent user experience."
               },
               {
                    "icon": "fas fa-check",
                    "content": "Outsource your SEO services for comprehensive quality assurance. Our dedicated team identifies and resolves potential issues, ensuring a seamless and delightful user experience on your website, optimized for search engines."
               },
               {
                    "icon": "fas fa-check",
                    "content": "Leverage the expertise of our seasoned SEO specialists. Choose an outsourcing provider committed to creating well-structured and scalable SEO strategies precisely tailored to the specific requirements of your online presence."
               }
          ],
          TextBoxData002: [
               {
                    "number": "01",
                    "title": "Define SEO Requirements",
                    "description": "Collaborate with our SEO experts to gather comprehensive requirements, understanding search engine optimization goals, specifications, cost estimation, and project timeline efficiently for streamlined online presence management."
               },
               {
                    "number": "02",
                    "title": "Select Optimal SEO Strategy",
                    "description": "Our SEO team meticulously selects the best strategy tailored to your website, ensuring optimal search engine visibility, performance, and scalability on various platforms."
               },
               {
                    "number": "03",
                    "title": "Create Mock UI for SEO Optimization",
                    "description": "Outsourcing SEO services brings expertise in creating a mock UI that visualizes the website's design and functionality, ensuring a seamless and visually appealing user experience aligned with the latest search engine optimization trends."
               },
               {
                    "number": "04",
                    "title": "Implement SEO Integration",
                    "description": "Outsourced SEO services ensure expertise in establishing a standardized optimization architecture, leveraging user-centric design principles for seamless communication between different components of your website on various platforms."
               },
               {
                    "number": "05",
                    "title": "Develop SEO for Online Presence",
                    "description": "Collaborate with our SEO team to accelerate the optimization of your online presence, aligning with specific requirements and prioritizing creative designs for streamlined online management."
               },
               {
                    "number": "06",
                    "title": "Conduct SEO Testing",
                    "description": "Outsourcing SEO services ensures quality and reliability through comprehensive testing services, facilitating faster optimization cycles and improving overall efficiency for your website, leading to improved search visibility and user-friendly online solutions."
               }
          ],
          IconWithTextData: [
               {
                    "icon": "line-icon-Code text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Comprehensive SEO Services",
                    "content": "Collaborate with us for comprehensive SEO services tailored to ensure the quality and reliability of your online presence. Our team excels in implementing efficient and scalable SEO solutions, guaranteeing optimal search visibility and user satisfaction."
               },
               {
                    "icon": "line-icon-Layout-Grid2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Adaptable SEO Solutions",
                    "content": "Elevate your business with our adaptable SEO solutions. We specialize in providing a range of services for SEO to address your specific requirements, offering flexibility and effective solutions for ensuring the quality of your online presence."
               },
               {
                    "icon": "line-icon-Cogs text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Integration Excellence for SEO Services",
                    "content": "Our services include seamless integration testing with 3rd party systems for SEO. We provide scalable and high-performance solutions tailored to your business needs through comprehensive integration services, ensuring the seamless operation of your online presence."
               },
               {
                    "icon": "line-icon-Tools text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Maintenance and Support for SEO Services",
                    "content": "Rely on us for secure maintenance and timely support for your SEO strategies. Stay ahead of the market with our dedicated deployment, maintenance, and support services, ensuring the smooth operation and continuous improvement of your online presence."
               }
          ],
          serviceData: [
               {
                    img: "/assets/img/webp/seo.webp",
                    title: "SEO Tools"
               },
               {
                    img: "/assets/img/webp/gsc.webp",
                    title: "Google Search Console"
               },
               {
                    img: "/assets/img/webp/mysql.webp",
                    title: "MySQL"
               },
               {
                    img: "/assets/img/webp/mongo.webp",
                    title: "MongoDB",
               },
               {
                    img: "/assets/img/webp/msazure.webp",
                    title: "Azure"
               },
               {
                    img: "/assets/img/webp/aws.webp",
                    title: "Amazon Web Services (AWS)"
               },
          ]

     },


     /** CMS development */

     "cms-custom-made-software-development": { //url
          title: "CMS Development Services",
          subTitle: "Elevate Your Digital Presence with CMS Excellence",
          heading: "Crafting Excellence in CMS Solutions with",
          headingName: "HasoTechnology",
          aboutContent: "Empower your business with our top-notch CMS development services. HasoTechnology is dedicated to delivering outstanding quality through cutting-edge CMS methodologies tailored for various digital content management requirements. Our team comprises skilled and experienced CMS specialists specializing in creating efficient and user-friendly digital experiences.",
          centerBigHeading: "Enhance Your Business with Comprehensive CMS Development",
          centerSmallHeading: "Unlock the full potential of your digital presence with our CMS development services. We prioritize meticulous development to guarantee optimal content management, website performance, and customer satisfaction.",
          techs: "Utilizing Advanced CMS Technologies.",
          process: "Efficient Process of Robust CMS Development for Enhanced User Satisfaction in Digital Markets",
          imgBody: "/assets/img/webp/cms.webp",
          listData: [
               {
                    "icon": "fas fa-check",
                    "content": "Collaborate with skilled CMS professionals for content management solutions that are optimized and user-friendly. Our cutting-edge methodologies cater to your unique needs, ensuring a website that efficiently manages digital content and provides an excellent user experience."
               },
               {
                    "icon": "fas fa-check",
                    "content": "Outsource your CMS services for comprehensive quality assurance. Our dedicated team identifies and resolves potential issues, ensuring a seamless and delightful user experience on your website, optimized for efficient content management."
               },
               {
                    "icon": "fas fa-check",
                    "content": "Leverage the expertise of our seasoned CMS specialists. Choose an outsourcing provider committed to creating well-structured and scalable CMS strategies precisely tailored to the specific requirements of your digital content management."
               }
          ],
          TextBoxData002: [
               {
                    "number": "01",
                    "title": "Define CMS Requirements",
                    "description": "Collaborate with our CMS experts to gather comprehensive requirements, understanding digital content management goals, specifications, cost estimation, and project timeline efficiently for streamlined online presence management."
                 },
                 {
                    "number": "02",
                    "title": "Select Optimal CMS Strategy",
                    "description": "Our CMS team meticulously selects the best strategy tailored to your website, ensuring optimal content management visibility, performance, and scalability on various platforms."
                 },
                 {
                    "number": "03",
                    "title": "Create Mock UI for CMS Development",
                    "description": "Outsourcing CMS services brings expertise in creating a mock UI that visualizes the website's design and functionality, ensuring a seamless and visually appealing user experience aligned with the latest content management trends."
                 },
                 {
                    "number": "04",
                    "title": "Implement CMS Integration",
                    "description": "Outsourced CMS services ensure expertise in establishing a standardized content management architecture, leveraging user-centric design principles for seamless communication between different components of your website on various platforms."
                 },
                 {
                    "number": "05",
                    "title": "Develop CMS for Online Presence",
                    "description": "Collaborate with our CMS team to accelerate the development of your online presence, aligning with specific requirements and prioritizing creative designs for streamlined online management."
                 },
                 {
                    "number": "06",
                    "title": "Conduct CMS Testing",
                    "description": "Outsourcing CMS services ensures quality and reliability through comprehensive testing services, facilitating faster development cycles and improving overall efficiency for your website, leading to improved content management visibility and user-friendly online solutions."
                 }
          ],
          IconWithTextData: [
               {
                    "icon": "line-icon-Code text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Comprehensive CMS Development Services",
                    "content": "Collaborate with us for comprehensive CMS development services tailored to ensure the quality and reliability of your online presence. Our team excels in implementing efficient and scalable CMS solutions, guaranteeing optimal content management visibility and user satisfaction."
                 },
                 {
                    "icon": "line-icon-Layout-Grid2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Adaptable CMS Solutions",
                    "content": "Elevate your business with our adaptable CMS solutions. We specialize in providing a range of services for CMS development to address your specific requirements, offering flexibility and effective solutions for ensuring the quality of your online presence."
                 },
                 {
                    "icon": "line-icon-Cogs text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Integration Excellence for CMS Development Services",
                    "content": "Our services include seamless integration testing with 3rd party systems for CMS development. We provide scalable and high-performance solutions tailored to your business needs through comprehensive integration services, ensuring the seamless operation of your online content management systems."
                 },
                 {
                    "icon": "line-icon-Tools text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
                    "title": "Maintenance and Support for CMS Development",
                    "content": "Rely on us for secure maintenance and timely support for your CMS strategies. Stay ahead of the market with our dedicated deployment, maintenance, and support services, ensuring the smooth operation and continuous improvement of your online content management systems."
                 }
          ],
          serviceData: [
               {
                    img: "/assets/img/webp/php.webp",
                    title: "PHP"
               },
               {
                    img: "/assets/img/webp/lara.webp",
                    title: "Laravel Framework"
               },
               {
                    img: "/assets/img/webp/mysql.webp",
                    title: "MySQL"
               },
               {
                    img: "/assets/img/webp/mongo.webp",
                    title: "MongoDB",
               },
               {
                    img: "/assets/img/webp/msazure.webp",
                    title: "Azure"
               },
               {
                    img: "/assets/img/webp/aws.webp",
                    title: "Amazon Web Services (AWS)"
               },
          ]

     },

          /** php development */

          "php-application-development": { //url
               title: "PHP Application Development Services",
               subTitle: "Elevate Your Digital Presence with PHP Excellence",
               heading: "Crafting Excellence in PHP Solutions with",
               headingName: "HasoTechnology",
               aboutContent: "Empower your business with our top-notch PHP application development services. HasoTechnology is dedicated to delivering outstanding quality through cutting-edge PHP methodologies tailored for various digital application requirements. Our team comprises skilled and experienced PHP specialists specializing in creating efficient and user-friendly digital experiences.",
               centerBigHeading: "Enhance Your Business with Comprehensive PHP Application Development",
               centerSmallHeading: "Unlock the full potential of your digital presence with our PHP application development services. We prioritize meticulous development to guarantee optimal application performance, robust functionality, and customer satisfaction.",
               techs: "Utilizing Advanced PHP Technologies.",
               process: "Efficient Process of Robust PHP Application Development for Enhanced User Satisfaction in Digital Markets",
               
               imgBody: "/assets/img/webp/php.webp",
               listData: [
                    {
                         "icon": "fas fa-check",
                         "content": "Collaborate with skilled PHP application developers for solutions that are optimized and user-friendly. Our cutting-edge methodologies cater to your unique needs, ensuring an application that efficiently manages digital content and provides an excellent user experience."
                     },
                     {
                         "icon": "fas fa-check",
                         "content": "Outsource your PHP application development for comprehensive quality assurance. Our dedicated team identifies and resolves potential issues, ensuring a seamless and delightful user experience in your application, optimized for efficient content management."
                     },
                     {
                         "icon": "fas fa-check",
                         "content": "Leverage the expertise of our seasoned PHP specialists. Choose an outsourcing provider committed to creating well-structured and scalable PHP strategies precisely tailored to the specific requirements of your application development."
                     }
                     
               ],
               TextBoxData002: [
                    {
                         "number": "01",
                         "title": "Define PHP Application Requirements",
                         "description": "Collaborate with stakeholders to gather comprehensive requirements, understanding goals, specifications, cost estimation, and project timeline efficiently for streamlined development."
                       },
                       {
                         "number": "02",
                         "title": "Select Optimal PHP Development Strategy",
                         "description": "Determine the best PHP development strategy based on requirements. Consider factors like scalability, performance, and platform compatibility. Choose appropriate PHP framework or development tools."
                       },
                       {
                         "number": "03",
                         "title": "Create Mock UI for PHP Application Development",
                         "description": "Develop a mock UI visualizing the design and functionality. Ensure a seamless and visually appealing user experience aligned with the latest trends in PHP application development."
                       },
                       {
                         "number": "04",
                         "title": "Implement PHP Application Integration",
                         "description": "Establish a standardized architecture. Leverage PHP frameworks and design principles for seamless communication between components. Ensure integration with other systems or databases."
                       },
                       {
                         "number": "05",
                         "title": "Develop PHP Application for Online Presence",
                         "description": "Collaborate with the development team to accelerate the PHP application development. Prioritize creative designs and user-centric features for a streamlined online presence."
                       },
                       {
                         "number": "06",
                         "title": "Conduct PHP Application Testing",
                         "description": "Implement comprehensive testing services for the PHP application. Conduct unit testing, integration testing, and system testing. Address security vulnerabilities for a secure and reliable application."
                       }
               ],
               IconWithTextData: [
                    {
                         "title": "Scalability and Flexibility",
                         "content": "PHP offers scalability and flexibility, allowing applications to grow and adapt to changing business needs efficiently."
                       },
                       {
                         "title": "Cost-Effective Development",
                         "content": "PHP is an open-source language, making it a cost-effective choice for development. No licensing fees contribute to budget-friendly solutions."
                       },
                       {
                         "title": "Rapid Development",
                         "content": "PHP enables rapid development with a vast ecosystem of libraries and frameworks. This accelerates the time-to-market for applications."
                       },
                       {
                         "title": "Cross-Platform Compatibility",
                         "content": "PHP applications can run on various platforms, providing cross-platform compatibility and accessibility for a broader audience."
                       },
                       {
                         "title": "Strong Community Support",
                         "content": "PHP has a robust and active community, ensuring continuous support, updates, and a wealth of resources for developers."
                       },
                       {
                         "title": "Security Features",
                         "content": "PHP includes built-in security features, and when used properly, it can help develop secure applications, safeguarding against common web threats."
                       }
               ],
               serviceData: [
                    {
                         img: "/assets/img/webp/php.webp",
                         title: "PHP"
                    },
                    {
                         img: "/assets/img/webp/lara.webp",
                         title: "Laravel Framework"
                    },
                    {
                         img: "/assets/img/webp/mysql.webp",
                         title: "MySQL"
                    },
                    {
                         img: "/assets/img/webp/mongo.webp",
                         title: "MongoDB",
                    },
                    {
                         img: "/assets/img/webp/msazure.webp",
                         title: "Azure"
                    },
                    {
                         img: "/assets/img/webp/aws.webp",
                         title: "Amazon Web Services (AWS)"
                    },
               ]
     
          },

                    /**crm-software-development */

                    "crm-software-development": { //url
                         title: "Custom crm software development",
                         subTitle: "Transform Your Business with CRM Excellence",
                         heading: "Crafting Excellence in CRM Solutions with",
                         headingName: "HasoTechnology",
                         aboutContent: "Empower your business with our top-notch CRM application development services. HasoTechnology is dedicated to delivering outstanding quality through cutting-edge CRM methodologies tailored for various digital application requirements. Our team comprises skilled and experienced CRM specialists specializing in creating efficient and user-friendly digital experiences.",
                         centerBigHeading: "Enhance Your Business with Comprehensive CRM Application Development",
                         centerSmallHeading: "Unlock the full potential of your digital presence with our CRM application development services. We prioritize meticulous development to guarantee optimal application performance, robust functionality, and customer satisfaction.",
                         techs: "Utilizing Advanced CRM Technologies.",
                         process: "Efficient Process of Robust CRM Application Development for Enhanced User Satisfaction in Digital Markets",
                         
                         imgBody: "/assets/img/webp/crm.webp",
                         listData: [
                              {
                                   "icon": "fas fa-check",
                                   "content": "Harness the power of skilled CRM application developers for solutions that are optimized and user-friendly. Our cutting-edge methodologies cater to your unique needs, ensuring an application that efficiently manages customer relations and provides an excellent user experience."
                                 },
                                 {
                                   "icon": "fas fa-check",
                                   "content": "Outsource your CRM application development for comprehensive quality assurance. Our dedicated team identifies and resolves potential issues, ensuring a seamless and delightful user experience in your application, optimized for efficient customer relationship management."
                                 },
                                 {
                                   "icon": "fas fa-check",
                                   "content": "Leverage the expertise of our seasoned CRM specialists. Choose an outsourcing provider committed to creating well-structured and scalable CRM strategies precisely tailored to the specific requirements of your application development."
                                 }
                               
                         ],
                         TextBoxData002: [
                              {
                                   "number": "01",
                                   "title": "Define CRM Application Requirements",
                                   "description": "Collaborate with stakeholders to gather comprehensive requirements, understanding goals, specifications, cost estimation, and project timeline efficiently for streamlined CRM development."
                                 },
                                 {
                                   "number": "02",
                                   "title": "Select Optimal CRM Development Strategy",
                                   "description": "Determine the best CRM development strategy based on requirements. Consider factors like scalability, performance, and platform compatibility. Choose appropriate CRM framework or development tools."
                                 },
                                 {
                                   "number": "03",
                                   "title": "Create Mock UI for CRM Application Development",
                                   "description": "Develop a mock UI visualizing the design and functionality. Ensure a seamless and visually appealing user experience aligned with the latest trends in CRM application development."
                                 },
                                 {
                                   "number": "04",
                                   "title": "Implement CRM Application Integration",
                                   "description": "Establish a standardized architecture. Leverage CRM frameworks and design principles for seamless communication between components. Ensure integration with other systems or databases."
                                 },
                                 {
                                   "number": "05",
                                   "title": "Develop CRM Application for Online Presence",
                                   "description": "Collaborate with the development team to accelerate the CRM application development. Prioritize creative designs and user-centric features for a streamlined online presence."
                                 },
                                 {
                                   "number": "06",
                                   "title": "Conduct CRM Application Testing",
                                   "description": "Implement comprehensive testing services for the CRM application. Conduct unit testing, integration testing, and system testing. Address security vulnerabilities for a secure and reliable CRM application."
                                 }
                         ],
                         IconWithTextData: [
                              {
                                   "title": "Scalability and Flexibility",
                                   "content": "CRM applications built on our platform offer scalability and flexibility, allowing them to grow and adapt to changing business needs efficiently."
                                 },
                                 {
                                   "title": "Cost-Effective Development",
                                   "content": "Our CRM development is cost-effective, leveraging open-source technologies. No licensing fees contribute to budget-friendly solutions."
                                 },
                                 {
                                   "title": "Rapid Development",
                                   "content": "Developing CRM solutions with us enables rapid development, thanks to a vast ecosystem of libraries and frameworks. This accelerates the time-to-market for CRM applications."
                                 },
                                 {
                                   "title": "Cross-Platform Compatibility",
                                   "content": "CRM applications developed by us can run on various platforms, providing cross-platform compatibility and accessibility for a broader audience."
                                 },
                                 {
                                   "title": "Strong Community Support",
                                   "content": "Our CRM development enjoys the support of a robust and active community, ensuring continuous support, updates, and a wealth of resources for developers."
                                 },
                                 {
                                   "title": "Security Features",
                                   "content": "Our CRM solutions incorporate built-in security features, ensuring that applications are developed securely and safeguarded against common web threats when used properly."
                                 }
                         ],
                         serviceData: [
                              {
                                   img: "/assets/img/webp/php.webp",
                                   title: "PHP"
                              },
                              {
                                   img: "/assets/img/webp/lara.webp",
                                   title: "Laravel Framework"
                              },
                              {
                                   img: "/assets/img/webp/mysql.webp",
                                   title: "MySQL"
                              },
                              {
                                   img: "/assets/img/webp/mongo.webp",
                                   title: "MongoDB",
                              },
                              {
                                   img: "/assets/img/webp/msazure.webp",
                                   title: "Azure"
                              },
                              {
                                   img: "/assets/img/webp/aws.webp",
                                   title: "Amazon Web Services (AWS)"
                              },
                         ]
               
                    },
};
