const TextSliderData01 = [
    {
        img: "https://via.placeholder.com/1200x702",
        subtitle: "Romantic",
        title: "Romantic getaways around the world",
        content: "Lorem ipsum dolor sit consectetur adipiscing eiusmod tempor incididunt labore dolore magna ut enim.",
        buttonTitle: "Explore vacation",
        buttonLink: "#"
    },
    {
        img: "https://via.placeholder.com/1200x702",
        subtitle: "Family",
        title: "Explore incredible deals on family tours",
        content: "Lorem ipsum dolor sit consectetur adipiscing eiusmod tempor incididunt labore dolore magna ut enim.",
        buttonTitle: "Explore vacation",
        buttonLink: "#"
    },
    {
        img: "https://via.placeholder.com/1200x702",
        subtitle: "Wildlife",
        title: "Explore a wide range of wildlife packages",
        content: "Lorem ipsum dolor sit consectetur adipiscing eiusmod tempor incididunt labore dolore magna ut enim.",
        buttonTitle: "Explore vacation",
        buttonLink: "#"
    }
]


const TextSliderData02 = [
    {
        "img": "/assets/img/webp/ecom1.webp",
        "title": "Tailored",
        "subtitle": "Ecommerce Solutions",
        "content": "Tailored Ecommerce solutions designed to elevate your online presence and boost sales.",
        "buttonTitle": "Learn more",
        "buttonLink": "/our-services"
    },
    {
        "img": "/assets/img/lms1.webp",
        "title": "Learning",
        "subtitle": "Management Systems",
        "content": "Cutting-edge Learning Management Systems to enhance education and training experiences.",
        "buttonTitle": "Learn more",
        "buttonLink": "/our-services"
    },
    {
        "img": "/assets/img/hms1.webp",
        "title": "Hospital",
        "subtitle": "Management Systems",
        "content": "Efficient Hospital Management Systems for streamlined patient care and administration.",
        "buttonTitle": "Learn more",
        "buttonLink": "/our-services"
    },
    {
        "img": "/assets/img/webp/erp1.webp",
        "title": "Project",
        "subtitle": "Management Systems",
        "content": "Comprehensive Project Management Systems for effective planning and execution.",
        "buttonTitle": "Learn more",
        "buttonLink": "/our-services"
    }
]


const TextSliderData03 = [
    {
        img: "https://via.placeholder.com/554x643",
        icon: "line-icon-Cookies",
        title: "Food delivery",
        content: "Lorem ipsum dolor amet consectetur adipiscing"
    },
    {
        img: "https://via.placeholder.com/554x643",
        icon: "line-icon-Drum",
        title: "Live music band",
        content: "Lorem ipsum dolor amet consectetur adipiscing"
    },
    {
        img: "https://via.placeholder.com/554x643",
        icon: "line-icon-Cocktail",
        title: "Bar and lounge",
        content: "Lorem ipsum dolor amet consectetur adipiscing"
    },
    {
        img: "https://via.placeholder.com/554x643",
        icon: "line-icon-Cauldron",
        title: "Modish foods",
        content: "Lorem ipsum dolor amet consectetur adipiscing"
    },
]

export { TextSliderData01, TextSliderData02, TextSliderData03 }