const ListData01 = [
    {
        icon: "fas fa-check",
        content: "Advanced customization theme options",
    },
    {
        icon: "fas fa-check",
        content: "Manage and share your workspace",
    },
    {
        icon: "fas fa-check",
        content: "Powerful theme for creatives designer",
    },
    {
        icon: "fas fa-check",
        content: "Browse amazing popular social features",
    },
]

const ListData02 = [
    {
        icon: "feather-arrow-right-circle",
        content: "Let's collaborate make an impact in the business",
    },
    {
        icon: "feather-arrow-right-circle",
        content: "A satisfied customer is the best business starts",
    },
    {
        icon: "feather-arrow-right-circle",
        content: "Expertise to build the things that matter for you",
    },
    {
        icon: "feather-arrow-right-circle",
        content: "Expertise to build the things that matter for you",
    },
]

const ListData03 = [
    {
        icon: "feather-check",
        content: "GREAT ENVIRONMENT",
    },
    {
        icon: "feather-check",
        content: "FREE BREAKFAST",
    },
    {
        icon: "feather-check",
        content: "CLEAN ROOM EVERYDAY",
    },
    {
        icon: "feather-check",
        content: "NO BOOKING FEE",
    },
]

const ListData04 = [
    {
        content: "Do I need a regular license or an extended license",
    },
    {
        content: "Any plugins that bundled with the theme I bought?",
    },
    {
        content: "Buyers guide to youtube content notices?",
    },
    {
        content: "Browse amazing social media features",
    },
]

const ListData05 = [
    {
        icon: "far fa-dot-circle",
        content: "Setting up a Shopify Store With Woocommerce",
    },
    {
        icon: "far fa-dot-circle",
        content: "Payment Geteway Integrations",
    },
    {
        icon: "far fa-dot-circle",
        content: "Whatsapp Api Integrations",
    },
    {
        icon: "far fa-dot-circle",
        content: "Lazada, Shopee, Shopify, Magento, Erp Integration Services",
    },
    {
        icon: "far fa-dot-circle",
        content: "Flipkart and Amazon Marketplace API Integration",
    },
    {
        icon: "far fa-dot-circle",
        content: "Web Real Time Communication (WEBRTC)",
    },
]

const ListData06 = [
    {
        content: "Beautiful and easy to understand UI, professional animations",
    },
    {
        content: "Theme advantages are pixel perfect design and clear code",
    },
    {
        content: "Present your services with flexible, convenient and multipurpose",
    },
    {
        content: "Find more creative ideas for your projects",
    },
    {
        content: "Unlimited power and customization possibilities",
    },
]

const ListData07 = [
    {
        icon: "fas fa-check",
        title: "Healthy profits for long term",
        content: "Lorem ipsum consectetur adipiscing eiusmod tempor incididunt labore.",
    },
    {
        icon: "fas fa-check",
        title: "Working towards your business",
        content: "Lorem ipsum consectetur adipiscing eiusmod tempor incididunt labore.",
    },
    {
        icon: "fas fa-check",
        title: "Advanced customization options",
        content: "Lorem ipsum consectetur adipiscing eiusmod tempor incididunt labore.",
    }
]

const ListData08 = [
    {
        linkTitle: "Entertainment",
        link: "#",
        number: "10",
    },
    {
        linkTitle: "Business",
        link: "#",
        number: "05",
    },
    {
        linkTitle: "Creative",
        link: "#",
        number: "03",
    },
    {
        linkTitle: "Lifestyle",
        link: "#",
        number: "02",
    },
    {
        linkTitle: "Fashion",
        link: "#",
        number: "19",
    },
    {
        linkTitle: "Design",
        link: "#",
        number: "21",
    },
]

export { ListData01, ListData02, ListData03, ListData04, ListData05, ListData06, ListData07, ListData08 }