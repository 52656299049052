const AccordionData = [
    {
        title: "Do you offer services beyond software development?",
        content: "We are working to cover all kinds of digital needs for your clients including Digital Marketing. Apart from software development, we are now stepping into Digital Marketing solutions."
    },
    {
        title: "What kind of projects do you develop?",
        content: "HasoTechnology has developed and delivered projects on various domains like eCommerce, Healthcare applications, POS applications, Cloud solutions, Omnichannel integrations, Marketplace applications, SAAS-based applications, CRM applications."
    },
    {
        title: "How does it take to complete a project?",
        content: "The development of a project period purely depends on the requirements but if you are looking for a simple eCommerce website for your store, we can get it delivered in 3 to 4 weeks. In the case of mobile apps, it will be in 5 to 6 weeks your app will be live in the play store/app store"
    },
    {
        title: "Do you provide after support?",
        content: "There will be 3 months of free support after the delivery of the application if you are taking our support plan for one year. Support includes maintaining the server, any application-related queries if any bugs with real-time data fixes, minor feature development, continuous backup of data, etc."
    },
    {
        title: "How do you guarantee product quality?",
        content: "We have our in-house Experienced Test Engineer who is responsible for the first level of testing directly from development. After getting clearance from the Test Engineer, there will be a review by the Project manager and followed by a Business analyst. After all these, before delivery, there will be a review by top management from HasoTechnology. With all these screening we ensure the project quality that we deliver."
    }
]

const AccordionData01 = [
    {
        title: "Which area is your specialist?",
        content: "HasoTechnology is a solution-oriented service provider. We will suggest proper technology solutions for your requirements and we have a strong team to serve it. Let it be a mobile application development or web application solution or desktop application-oriented solutions. We can serve on these platforms. To be very precise, we are strong on developing Web and Mobile based solutions including hybrid platforms. "
    },
    {
        title: "How much does it cost for a project?",
        content: "The cost of the project purely depends on the requirements or the solutions that we are offering but if you are looking for a generic solution like an eCommerce platform it would cost USD 1300 to USD 2000 approximately."
    },
    {
        title: "What are the technologies your using?",
        content: "We use MEAN stack and LAMP stack technologies to develop solutions."
    },
    {
        title: "Can you maintain our website?",
        content: "Yes, we do maintain your websites. "
    },
    {
        title: "What is your experience?",
        content: "HasoTechnology has been in the software development industry since 2015. With a lot of experience in the industry, we are with a strong team that can handle and can provide solutions."
    }
    
]

const AccordionData1 = [
    {
        title: "What is Mobile App Development?",
        content: "Mobile App Development is like building a digital world in the palm of your hand. It's the art and science of creating applications that run on mobile devices like smartphones and tablets. Think of it as crafting a mini universe of functionality, design, and user experience tailored to fit snugly into your pocket."
    },
    {
        title: "What programming languages are commonly used for Mobile App Development?",
        content: "Mobile App Development is a multilingual affair. Java and Kotlin are the power couple for Android, speaking the language of Google. On the iOS side, Swift and Objective-C are the dynamic duo, conversing in Apple's native tongue. If you're into cross-platform development, JavaScript with frameworks like React Native or Dart with Flutter can be your universal translators. It's like a polyglot coding party catering to different devices and platforms."
    },
    {
        title: "What are some common Challenges in Mobile App Development?",
        content: "Ah, the rollercoaster ride of challenges! First up, there's the compatibility tango. Ensuring your app dances smoothly on various devices and screen sizes can be tricky. Then there's the performance waltz – nobody likes a sluggish app. Security is the guardian at the gate, protecting user data. Lastly, staying in sync with ever-evolving technologies is like keeping up with the coolest dance moves. Mobile App Development is a dance floor, and overcoming these challenges makes you the star dancer."
    },
    {
        title: "How do I Choose the Right Mobile App Development Company in Canada?",
        content: "Choosing the right Mobile App Development Company is like finding your business soulmate. Look for a company that speaks your language, understands your vision, and has a track record of successful projects. Check their portfolio – it's like flipping through a developer's photo album. Reviews and testimonials are the whispers of satisfied clients. And don't forget the chemistry – you want a company that vibes with your goals and communicates seamlessly. It's not just about the code; it's about finding a development partner that makes your app dreams come true."
    },
    {
        title: "How Much does It Cost to Develop a Mobile App?",
        content: "The cost of developing a mobile app is like designing a custom car. It depends on the features you want, the complexity of the design, and the platform you're building for. A simple app might be like a compact car, budget-friendly. A more complex app with advanced features is like a luxury sedan, a bit more expensive but oh-so-impressive. The key is to discuss your app's needs with developers, get a tailored quote, and ensure you're investing wisely in creating the mobile experience you envision."
    }
]

const AccordionDataCrmLeft = [
    {
        title: "What is Custom CRM Software Development?",
        content: "Custom CRM Software Development refers to the process of creating a Customer Relationship Management system tailored to the specific needs and requirements of a business. Unlike off-the-shelf CRM solutions, custom development allows companies to design a CRM platform that aligns precisely with their unique workflows and objectives."
    },
    {
        title: "How long does it take to develop a CRM system?",
        content: "The development time for a CRM system depends on its complexity and features. A basic CRM may take a few months, while more advanced systems can take six months to a year or more. Rapid development can be achieved by using existing platforms and customizing them to suit your business requirements."
    },
    {
        title: "Are there hidden costs in CRM development?",
        content: "It's crucial to consider potential hidden costs, such as data migration, training, and ongoing support. Additionally, customization beyond standard features may incur extra expenses. Thoroughly discussing requirements with the development team can help identify and budget for potential hidden costs."
    },
    
]

const AccordionDataCrmRight = [
    {
        title: "What technologies are commonly used in custom CRM development?",
        content: "Common technologies used in custom CRM development include database management systems (e.g., MySQL, MongoDB), programming languages (e.g., Java, Python), and web development frameworks (e.g., Angular, React)."
    },
    {
        title: "Is CRM development suitable for small businesses?",
        content: "Yes, CRM development is beneficial for small businesses. Custom CRMs can be scaled according to the size and needs of a business, providing an affordable and tailored solution to help manage customer relationships effectively."
    },
    {
        title: "What is your experience?",
        content: "HasoTechnology has been in the software development industry since 2015. With a lot of experience in the industry, we are with a strong team that can handle and can provide solutions."
    }
    
]

const AccordionDataAngularLeft = [
    {
        title: "What are the advantages of outsourcing AngularJS development?",
        content: "Outsourcing AngularJS development can offer cost savings, access to a global talent pool, and faster project delivery. It allows you to focus on your core business while experts handle the technical aspects of your project."
    },
    {
        title: "Can an AngularJS development company customize solutions according to my business needs?",
        content: "A reputable AngularJS development company will work closely with you to understand your business requirements and tailor solutions that align with your goals. Customization is a key strength of experienced development teams."
    },
    {
        title: "Can I use Angular for mobile app development?",
        content: "Absolutely! Angular is a versatile framework that works wonders for mobile app development."
    },
    {
        title: "Why Choose Angular for Web Apps?",
        content: "Angular is like the superhero of web development. It's not just a framework; it's a game-changer. You choose Angular because it simplifies the complex. It's the Batman utility belt for developers, packed with tools to tackle any web app challenge."
    },
    {
        title: "How much does Angular development cost?",
        content: "The cost depends on your project's scope and complexity. It's like asking how much a custom suit costs – it varies. But here's the beauty: Angular's efficiency can save you development hours, translating into cost savings in the long run."
    }
]

const AccordionDataAngularRight = [
    {
        title: "Is Angular suitable for startups with a tight budget?",
        content: "Tight budgets are the startups' trademark, right? The good news is, Angular loves a challenge. It's not just for the big players; it's for the David facing Goliath too. With Angular, you get a powerful toolset without breaking the bank. It's like having a Ferrari performance on a startup budget."
    },
    {
        title: "Can Angular handle large-scale applications?",
        content: "Absolutely! Angular isn't afraid of the big leagues. It scales like a champion weightlifter. Whether you're building a small blog or a massive e-commerce platform, Angular's got the muscles to handle it."
    },
    {
        title: "Is it possible to add other features after development?",
        content: "Absolutely! Think of your app like a Lego set. Even after the initial build, you can keep adding new pieces. With Angular's modular architecture, incorporating additional features is as smooth as adding a new Lego block to your masterpiece. So yes, the answer is a resounding yes."
    },
    {
        title: "What is the MVC pattern in Angular?",
        content: "MVC, or Model-View-Controller, is like the architectural maestro behind Angular's brilliance. Picture it like a three-part harmony in a song. The Model is your data – the backbone. The View is the user interface – what users see and interact with. The Controller is the maestro, orchestrating the interaction between Model and View. Angular's implementation of MVC creates a symphony of code that's organized, maintainable, and a joy to work with."
    },
    {
        title: "Why is Angular so popular?",
        content: "Angular's popularity is like wildfire, spreading fast and bright. There are a few key reasons. First off, it's backed by Google, the tech giant. Trust matters, and with Google in the mix, you know you're in good hands. Second, Angular offers a comprehensive toolbox for developers. It's not just a framework; it's a Swiss Army knife, equipped with everything you need for robust web app development. Lastly, Angular's community is like a lively party. Developers share insights, solve problems together, and ensure that everyone's invited to the coding celebration. It's this combination of support, functionality, and a robust architecture that makes Angular the star of the web development show."
    }
]


const AccordionDataMobileRight = [
    {
        title: "Do you monitor the performance of mobile apps?",
        content: "Yes at HasoTech, Monitoring the performance of mobile apps is like having a dashboard for your digital vehicle. We keep a keen eye on various metrics – from app responsiveness to user interactions. It's about ensuring your app runs smoothly, identifies and addresses issues promptly, and provides users with a seamless experience. It's like having a digital health checkup for your app."
    },
    {
        title: "What are the different hiring models offered by you?",
        content: "At HasoTech, we believe in flexibility. We offer three distinct hiring models tailored to meet our clients' diverse needs: Full-time hiring, Part-time hiring, Hourly basis hiring"
    },
    {
        title: "What are the technologies your using?",
        content: "We use MEAN stack and LAMP stack technologies to develop solutions."
    },
    {
        title: "Can you maintain our website?",
        content: "Yes, we do maintain your websites. "
    },
    {
        title: "What is your experience?",
        content: "HasoTechnology has been in the software development industry since 2015. With a lot of experience in the industry, we are with a strong team that can handle and can provide solutions."
    }
    
]

const AccordionData02 = [
    {
        title: "What do you mean by item and end product?",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever when an unknown printer took a galley. Lorem ipsum is simply."
    },
    {
        title: "What are some examples of permitted end products?",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever when an unknown printer took a galley. Lorem ipsum is simply."
    },
    {
        title: "Am i allowed to modify the item that i purchased?",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever when an unknown printer took a galley. Lorem ipsum is simply."
    }
]

const AccordionData05 = [
    {
        time: "06:00 - 07:00",
        title: 'Yoga and Pilates',
        content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
        author: 'By Edward watson'
    },
    {
        time: "08:00 - 09:00",
        title: 'Energy Blast',
        content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
        author: 'By Bryan jonhson'
    },
    {
        time: "10:00 - 11:00",
        title: 'Cardio Workout',
        content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
        author: 'By Jeremy dupont',

    },
    {
        time: "11:00 - 12:00",
        title: 'Athletics Classes',
        content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
        author: ' By Edward watson'
    },
    {
        time: "13:00 - 15:00",
        title: 'Boxercise',
        content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
        author: ' By Bryan jonhson',

    }
]

export {AccordionData , AccordionData01, AccordionDataMobileRight , AccordionData1, AccordionData02,AccordionData05, AccordionDataCrmLeft, AccordionDataCrmRight, AccordionDataAngularLeft, AccordionDataAngularRight};