const ClientData01 = [
    {
        img: '/assets/img/webp/gcpa.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/drravi.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/vel.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/abilogo.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/beautyconnect.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/netsuite.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/ink.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/attune.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/aventria.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/a2000.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/lion.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/xlr.webp',
        target: "_self",
        link: '#'
    },


    {
        img: '/assets/img/webp/whar.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/altimus.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/ckslogo.webp',
        target: "_self",
        link: '#'
    },

    {
        img: '/assets/img/webp/triangle.webp',
        target: "_self",
        link: '#'
    },
]

const ClientData02 = [
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    }
]

const ClientData03 = [
    {
        img: 'https://via.placeholder.com/400x160',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/400x160',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/400x160',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/400x160',
        target: "_self",
        link: '#'
    }
]

const ClientData04 = [
    {
        img: 'https://via.placeholder.com/120x60',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/120x60',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/120x60',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/120x60',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/120x60',
        target: "_self",
        link: '#'
    }
]


const ClientData05 = [
    {
        img: '/assets/img/webp/orcl.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/ama.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/flip.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/laz.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/agora.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/stripe.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/razor.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/graph.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/shopify.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/shopee.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/ariba.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/qoo.webp',
        target: "_self",
        link: '#'
    },

    {
        img: '/assets/img/webp/nsdl.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/paypal.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/gmaps.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/openstreet.webp',
        target: "_self",
        link: '#'
    },

    {
        img: '/assets/img/webp/ipay.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/cdsl.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/karvyy.webp',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/webp/digi.webp',
        target: "_self",
        link: '#'
    },
]

const ClientData06 = [
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
]

const ClientData07 = [
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    }
]

const ClientData08 = [
    {
        img: "https://via.placeholder.com/80x92"
    },
    {
        img: "https://via.placeholder.com/80x92"
    },
    {
        img: "https://via.placeholder.com/80x92"
    },
    {
        img: "https://via.placeholder.com/80x92"
    }
]


export { ClientData01, ClientData02, ClientData03, ClientData04, ClientData05, ClientData06, ClientData07, ClientData08 };