const HeaderData = [
    {
        title: 'Home',
        link: '/'
        // megamenu: [
        //     {
        //         title: 'Corporate',
        //         dropdown: [
        //             {
        //                 title: 'Startup',
        //                 link: '/home-startup'
        //             },
        //             {
        //                 title: 'Business',
        //                 link: '/home-business'
        //             },
        //             {
        //                 title: 'Corporate',
        //                 link: '/home-corporate'
        //             },
        //             {
        //                 title: 'Finance',
        //                 link: '/home-finance'
        //             },
        //             {
        //                 title: 'Application',
        //                 link: '/home-application'
        //             },
        //             {
        //                 title: 'Consulting',
        //                 link: '/home-consulting'
        //             },
        //             {
        //                 title: 'Digital agency',
        //                 link: '/home-digital-agency'
        //             },
        //             {
        //                 title: 'SEO agency',
        //                 link: '/home-seo-agency'
        //             },
        //             {
        //                 title: 'Events & conference',
        //                 link: '/home-events-conference'
        //             },
        //             {
        //                 title: 'Marketing agency',
        //                 link: '/home-marketing-agency'
        //             }
        //         ],
        //     },
        //     {
        //         title: 'Specialized',
        //         dropdown: [
        //             {
        //                 title: 'Restaurant',
        //                 link: '/home-restaurant',
        //             },
        //             {
        //                 title: 'Architecture',
        //                 link: '/home-architecture',
        //             },
        //             {
        //                 title: 'Hotel & resort',
        //                 link: '/home-hotel-resort',
        //             },
        //             {
        //                 title: 'Travel agency',
        //                 link: '/home-travel-agency',
        //             },
        //             {
        //                 title: 'Yoga & meditation',
        //                 link: '/home-yoga-meditation',
        //             },
        //             {
        //                 title: 'Gym & fitness',
        //                 link: '/home-gym-fitness',
        //             },
        //             {
        //                 title: 'Spa salon',
        //                 link: '/home-spa-salon',
        //             },
        //             {
        //                 title: 'Cafe',
        //                 link: '/home-cafe',
        //             },
        //             {
        //                 title: 'Home decor',
        //                 link: '/home-decor',
        //             },
        //             {
        //                 title: 'Dentist',
        //                 link: '/home-dentist',
        //             },
        //             {
        //                 title: 'Interior design',
        //                 link: '/home-interior-design',
        //             },
        //         ],
        //     },
        //     {
        //         title: 'Portfolio',
        //         dropdown: [
        //             {
        //                 title: 'Design agency',
        //                 link: '/home-design-agency',
        //             },
        //             {
        //                 title: 'Web agency',
        //                 link: '/home-web-agency',
        //             },
        //             {
        //                 title: 'Creative agency',
        //                 link: '/home-creative-agency',
        //             },
        //             {
        //                 title: 'Freelancer',
        //                 link: '/home-freelancer',
        //             },
        //             {
        //                 title: 'Branding agency',
        //                 link: '/home-branding-agency',
        //             },
        //             {
        //                 title: 'Photography',
        //                 link: '/home-photography',
        //             },
        //             {
        //                 title: 'Personal portfolio',
        //                 link: '/home-personal-portfolio',
        //             },
        //             {
        //                 title: 'Vertical portfolio',
        //                 link: '/home-vertical-portfolio',
        //             },
        //             {
        //                 title: 'Interactive portfolio',
        //                 link: '/home-interactive-portfolio',
        //             },
        //             {
        //                 title: 'Split portfolio',
        //                 link: '/home-split-portfolio',
        //             },
        //             {
        //                 title: 'Creative portfolio',
        //                 link: '/home-creative-portfolio',
        //             }
        //         ],
        //     },
        //     {
        //         title: 'Other',
        //         dropdown: [
        //             {
        //                 title: 'Furniture shop',
        //                 link: '/home-furniture-shop',
        //             },
        //             {
        //                 title: 'Fashion shop',
        //                 link: '/home-fashion-shop',
        //             },
        //             {
        //                 title: 'Magazine',
        //                 link: '/home-magazine',
        //             },
        //             {
        //                 title: 'Lifestyle blog',
        //                 link: '/home-lifestyle-blog',
        //             },
        //             {
        //                 title: 'Classic blog',
        //                 link: '/home-classic-blog',
        //             },
        //             {
        //                 title: 'Blog metro',
        //                 link: '/home-blog-metro',
        //             }
        //         ],
        //     },
        // ]
    },
    {
        title: 'About',
        link: '/about-us'
        // dropdown: [
        //     {
        //         title: 'About',
        //         dropdown: [
        //             {
        //                 title: 'About me',
        //                 link: '/page/about-me'
        //             },
        //             {
        //                 title: 'About us',
        //                 link: '/about-us'
        //             },
        //             {
        //                 title: 'Our story',
        //                 link: '/page/our-story'
        //             },
        //             {
        //                 title: 'Who we are',
        //                 link: '/page/who-we-are'
        //             }
        //         ]
        //     },
        //     {
        //         title: 'Services',
        //         dropdown: [
        //             {
        //                 title: 'Our services',
        //                 link: '/our-services'
        //             },
        //             {
        //                 title: 'What we offer',
        //                 link: '/page/what-we-offer'
        //             },
        //             {
        //                 title: 'Our process',
        //                 link: '/our-process'
        //             }
        //         ]
        //     },
        //     {
        //         title: 'Contact',
        //         dropdown: [
        //             {
        //                 title: 'Contact simple',
        //                 link: '/page/contact-simple'
        //             },
        //             {
        //                 title: 'Contact classic',
        //                 link: '/page/contact-classic'
        //             },
        //             {
        //                 title: 'Contact modern',
        //                 link: '/page/contact-modern'
        //             }
        //         ]
        //     },
        //     {
        //         title: 'Additional pages',
        //         dropdown: [
        //             {
        //                 title: 'Our team',
        //                 link: '/our-team'
        //             },
        //             {
        //                 title: 'Latest news',
        //                 link: '/page/latest-news'
        //             },
        //             {
        //                 title: 'Pricing packages',
        //                 link: '/page/pricing-packages'
        //             },
        //             {
        //                 title: 'Error 404',
        //                 link: '/page/error-404'
        //             },
        //             {
        //                 title: 'Maintenance',
        //                 link: '/page/maintenance'
        //             },
        //             {
        //                 title: 'Coming soon',
        //                 link: '/page/coming-soon'
        //             },
        //             {
        //                 title: 'Coming soon - V2',
        //                 link: '/page/coming-soon-V2'
        //             },
        //             {
        //                 title: "FAQ's",
        //                 link: '/faq'
        //             },
        //             {
        //                 title: "Search result",
        //                 link: '/page/search-result'
        //             },
        //         ]
        //     },
        // ]
    },
    {
        title: 'Our Approach',
        link: '/our-process'
        // dropdown: [
        //     {
        //         title: 'Portfolio classic',
        //         dropdown: [
        //             {
        //                 title: 'Classic 2 column',
        //                 link: '/portfolio/portfolio-classic-two-column'
        //             },
        //             {
        //                 title: 'Classic 3 column',
        //                 link: '/portfolio/portfolio-classic-three-column'
        //             },
        //             {
        //                 title: 'Classic 4 column',
        //                 link: '/portfolio/portfolio-classic-four-column'
        //             },
        //             {
        //                 title: 'Classic masonry',
        //                 link: '/portfolio/portfolio-classic-masonry'
        //             },
        //             {
        //                 title: 'Classic metro',
        //                 link: '/portfolio/portfolio-classic-metro'
        //             }
        //         ]
        //     },
        //     {
        //         title: 'Portfolio boxed',
        //         dropdown: [
        //             {
        //                 title: 'Boxed 2 column',
        //                 link: '/portfolio/portfolio-boxed-two-column'
        //             },
        //             {
        //                 title: 'Boxed 3 column',
        //                 link: '/portfolio/portfolio-boxed-three-column'
        //             },
        //             {
        //                 title: 'Boxed 4 column',
        //                 link: '/portfolio/portfolio-boxed-four-column'
        //             },
        //             {
        //                 title: 'Boxed masonry',
        //                 link: '/portfolio/portfolio-boxed-masonry'
        //             },
        //             {
        //                 title: 'Boxed metro',
        //                 link: '/portfolio/portfolio-boxed-metro'
        //             }
        //         ]
        //     },
        //     {
        //         title: 'Portfolio colorful',
        //         dropdown: [
        //             {
        //                 title: 'Colorful 2 column',
        //                 link: '/portfolio/portfolio-colorful-two-column'
        //             },
        //             {
        //                 title: 'Colorful 3 column',
        //                 link: '/portfolio/portfolio-colorful-three-column'
        //             },
        //             {
        //                 title: 'Colorful 4 column',
        //                 link: '/portfolio/portfolio-colorful-four-column'
        //             },
        //             {
        //                 title: 'Colorful masonry',
        //                 link: '/portfolio/portfolio-colorful-masonry'
        //             },
        //             {
        //                 title: 'Colorful metro',
        //                 link: '/portfolio/portfolio-colorful-metro'
        //             }
        //         ]
        //     },
        //     {
        //         title: 'Portfolio bordered',
        //         dropdown: [
        //             {
        //                 title: 'Bordered 2 column',
        //                 link: '/portfolio/portfolio-bordered-two-column'
        //             },
        //             {
        //                 title: 'Bordered 3 column',
        //                 link: '/portfolio/portfolio-bordered-three-column'
        //             },
        //             {
        //                 title: 'Bordered 4 column',
        //                 link: '/portfolio/portfolio-bordered-four-column'
        //             },
        //             {
        //                 title: 'Bordered masonry',
        //                 link: '/portfolio/portfolio-bordered-masonry'
        //             },
        //             {
        //                 title: 'Bordered metro',
        //                 link: '/portfolio/portfolio-bordered-metro'
        //             }
        //         ]
        //     },
        //     {
        //         title: 'Portfolio overlay',
        //         dropdown: [
        //             {
        //                 title: 'Overlay 2 column',
        //                 link: '/portfolio/portfolio-overlay-two-column'
        //             },
        //             {
        //                 title: 'Overlay 3 column',
        //                 link: '/portfolio/portfolio-overlay-three-column'
        //             },
        //             {
        //                 title: 'Overlay 4 column',
        //                 link: '/portfolio/portfolio-overlay-four-column'
        //             },
        //             {
        //                 title: 'Overlay masonry',
        //                 link: '/portfolio/portfolio-overlay-masonry'
        //             },
        //             {
        //                 title: 'Overlay metro',
        //                 link: '/portfolio/portfolio-overlay-metro'
        //             }
        //         ]
        //     },
        //     {
        //         title: 'Portfolio switch image',
        //         dropdown: [
        //             {
        //                 title: 'Switch Image 2 column',
        //                 link: '/portfolio/portfolio-switch-image-two-column'
        //             },
        //             {
        //                 title: 'Switch Image 3 column',
        //                 link: '/portfolio/portfolio-switch-image-three-column'
        //             },
        //             {
        //                 title: 'Switch Image 4 column',
        //                 link: '/portfolio/portfolio-switch-image-four-column'
        //             },
        //             {
        //                 title: 'Switch Image masonry',
        //                 link: '/portfolio/portfolio-switch-image-masonry'
        //             },
        //             {
        //                 title: 'Switch Image metro',
        //                 link: '/portfolio/portfolio-switch-image-metro'
        //             }
        //         ]
        //     },
        //     {
        //         title: 'Portfolio other',
        //         dropdown: [
        //             {
        //                 title: 'Portfolio scattered',
        //                 link: '/portfolio/portfolio-scattered'
        //             },
        //             {
        //                 title: 'Justified gallery',
        //                 link: '/portfolio/portfolio-justified-gallery'
        //             },
        //             {
        //                 title: 'Portfolio slider',
        //                 link: '/portfolio/portfolio-slider'
        //             },
        //         ]

        //     },
        //     {
        //         title: 'Single project page',
        //         dropdown: [
        //             {
        //                 title: 'Single project page 01',
        //                 link: '/portfolio/single-project-page-01'
        //             },
        //             {
        //                 title: 'Single project page 02',
        //                 link: '/portfolio/single-project-page-02'
        //             },
        //             {
        //                 title: 'Single project page 03',
        //                 link: '/portfolio/single-project-page-03'
        //             },
        //             {
        //                 title: 'Single project page 04',
        //                 link: '/portfolio/single-project-page-04'
        //             },
        //             {
        //                 title: 'Single project page 05',
        //                 link: '/portfolio/single-project-page-05'
        //             }
        //         ]
        //     }
        // ],
    },
    {
        title : 'Our Services',
        link : '/our-services',
    },
    {
        title : 'Our Portfolio',
        link : '/our-portfolio',
    },
    {
        title: 'Our Clients',
        link:'/our-clients',
    },
    {
        title: 'Contact us',
        link: '/contact-us'
    },

    {
        title : 'Career',
        link : '/our-team',
    },
    {
        title : 'Blog',
        link : 'https://blog.hasotech.com/',
    },
    {
        title : 'FAQs',
        link : '/faq',
    },
   
    // {
    //     title: 'Blog',
    //     dropdown: [
    //         {
    //             title: 'Blog grid',
    //             link: '/blogs/blog-grid'
    //         },
    //         {
    //             title: 'Blog masonry',
    //             link: '/blogs/blog-masonry'
    //         },
    //         {
    //             title: 'Blog classic',
    //             link: '/blogs/blog-classic'
    //         },
    //         {
    //             title: 'Blog simple',
    //             link: '/blogs/blog-simple'
    //         },
    //         {
    //             title: 'Blog side image',
    //             link: '/blogs/blog-side-image'
    //         },
    //         {
    //             title: 'Blog metro',
    //             link: '/blogs/blog-metro'
    //         },
    //         {
    //             title: 'Blog overlay image',
    //             link: '/blogs/blog-overlay-image'
    //         },
    //         {
    //             title: 'Blog modern',
    //             link: '/blogs/blog-modern'
    //         },
    //         {
    //             title: 'Blog clean',
    //             link: '/blogs/blog-clean'
    //         },
    //         {
    //             title: 'Blog widget',
    //             link: '/blogs/blog-widget'
    //         },
    //         {
    //             title: 'Blog standard',
    //             link: '/blogs/blog-standard'
    //         },
    //         {
    //             title: 'Post layout',
    //             dropdown: [
    //                 {
    //                     title: 'Blog post layout 01',
    //                     link: '/blogs/blog-post-layout-01'
    //                 },
    //                 {
    //                     title: 'Blog post layout 02',
    //                     link: '/blogs/blog-post-layout-02'
    //                 },
    //                 {
    //                     title: 'Blog post layout 03',
    //                     link: '/blogs/blog-post-layout-03'
    //                 },
    //                 {
    //                     title: 'Blog post layout 04',
    //                     link: '/blogs/blog-post-layout-04'
    //                 },
    //                 {
    //                     title: 'Blog post layout 05',
    //                     link: '/blogs/blog-post-layout-05'
    //                 },
    //             ]
    //         },
    //         {
    //             title: 'Post types',
    //             dropdown: [
    //                 {
    //                     title: 'Standard post',
    //                     link: '/blog-types/blog-standard-post/1'
    //                 },
    //                 {
    //                     title: 'Gallery post',
    //                     link: '/blog-types/blog-gallery-post/1'
    //                 },
    //                 {
    //                     title: 'Slider post',
    //                     link: '/blog-types/blog-slider-post/1'
    //                 },
    //                 {
    //                     title: 'HTML5 video post',
    //                     link: '/blog-types/blog-html5-video-post/1'
    //                 },
    //                 {
    //                     title: 'Youtube video post',
    //                     link: '/blog-types/blog-youtube-video-post/1'
    //                 },
    //                 {
    //                     title: 'Vimeo video post',
    //                     link: '/blog-types/blog-vimeo-video-post/1'
    //                 },
    //                 {
    //                     title: 'Audio post',
    //                     link: '/blog-types/blog-audio-post/1'
    //                 },
    //                 {
    //                     title: 'Blockquote post',
    //                     link: '/blog-types/blog-blockquote-post/1'
    //                 },
    //                 {
    //                     title: 'Full width post',
    //                     link: '/blog-types/blog-full-width-post/1'
    //                 },
    //             ]
    //         },
    //     ]
    // },
    // {
    //     title: "Shop",
    //     megamenu: [
    //         {
    //             title: 'Shop layout',
    //             dropdown: [
    //                 {
    //                     title: 'Shop wide',
    //                     link: '/shop/shop-wide'
    //                 },
    //                 {
    //                     title: 'Left sidebar',
    //                     link: '/shop/left-sidebar'
    //                 },
    //                 {
    //                     title: 'Right sidebar',
    //                     link: '/shop/right-sidebar'
    //                 },
    //                 {
    //                     title: 'Only categories',
    //                     link: '/shop/only-categories'
    //                 },
    //                 {
    //                     title: 'Single product',
    //                     link: '/shop/single-product'
    //                 },
    //             ],
    //         },
    //         {
    //             title: 'Utility pages ',
    //             dropdown: [
    //                 {
    //                     title: 'Shopping cart',
    //                     link: '/shop/shopping-cart'
    //                 },
    //                 {
    //                     title: 'Checkout',
    //                     link: '/shop/checkout'
    //                 },
    //                 {
    //                     title: 'Login / Register',
    //                     link: '/shop/login-register'
    //                 }
    //             ]
    //         },
    //         {
    //             dropdown: [
    //                 {
    //                     link: "/",
    //                     img: "/assets/img/webp/menu-banner-02.webp",
    //                 }
    //             ]
    //         }
    //     ],
    // }
]

export default HeaderData