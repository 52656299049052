const FooterData = [
    {
        title: "company",
        submenu: [
            {
                title: "About company",
                link: "/about-us"
            },
            {
                title: "Company services",
                link: "/our-services"
            },
            {
                title: "Career",
                link: "/our-team"
            },
            {
                title: "Contact us",
                link: "/page/contact-classic"
            },
        ]
    },
    {
        title: "Services",
        submenu: [
            {
                title: "MEAN Stack Development",
                link: "/faq"
            },
            {
                title: "MERN Stack Development",
                link: "/page/pricing-packages"
            },
            {
                title: "PHP Development",
                link: "/page/our-story"
            },
            {
                title: "Digital Marketing",
                link: "/our-process"
            },
        ]
    },
    {
        title: "Additional",
        submenu: [
            {
                title: "Our story",
                link: "/page/our-story"
            },
            {
                title: "Who we are",
                link: "/page/who-we-are"
            },
            {
                title: "Our process",
                link: "/our-process"
            },
            {
                title: "Latest news",
                link: "/page/latest-news"
            },
        ]
    },
    {
        title: "Services",
        submenu: [
            {

                title: "Brand experience",
                link: "/our-services"
            },
            {
                title: "e-Commerce website",
                link: "/our-services"
            },
            {
                title: "Content writing",
                link: "/our-services"
            },
            {
                title: "Marketing strategy",
                link: "/our-services"
            },
        ]
    },
    {
        title: "Resources",
        submenu: [
            {
                title: "Theme guide",
                link: "/faq"
            },
            {
                title: "Support desk",
                link: "/faq"
            },
            {
                title: "What we offer",
                link: "/page/what-we-offer"
            },
            {
                title: "Company history",
                link: "/page/our-story"
            },
        ]
    },
    {
        title: "Categories",
        submenu: [
            {
                title: "For men",
                link: "/shop/shop-wide"
            },
            {
                title: "For woman",
                link: "/shop/shop-wide"
            },
            {
                title: "Accessories",
                link: "/shop/shop-wide"
            },
            {
                title: "Collections",
                link: "/shop/shop-wide"
            },
        ]
    },
    {
        title: "Adavantages",
        submenu: [
            {
                title: "Free delivery",
                link: "/faq"
            },
            {
                title: "100 days refund",
                link: "/faq"
            },
            {
                title: "Multiple payments",
                link: "/faq"
            },
            {
                title: "Sustainable",
                link: "/faq"
            },
        ]
    },
]

export default FooterData