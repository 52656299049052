const serviceData1 = [
    {
        img: "/assets/img/webp/ionic.webp",
        title: "Ionic Framework"
    },
    {
        img: "/assets/img/webp/nodejs.webp",
        title: "NodeJs"
    },
    {
        img: "/assets/img/webp/mysql.webp",
        title: "MySQL"
    },
    {
        img: "/assets/img/webp/mongo.webp",
        title: "MongoDB",
    },
    {
        img: "/assets/img/webp/azure.webp",
        title: "Azure"
    },
    {
        img: "/assets/img/webp/aws.webp",
        title: "Amazon Web Services (AWS)"
    },
]

const serviceData2 = [
    {
        img: "/assets/img/webp/wp.webp",
        title: "Wordpress"
    },
    {
        img: "/assets/img/webp/php.webp",
        title: "PHP"
    },
    {
        img: "/assets/img/webp/mysql.webp",
        title: "MySQL"
    },
    {
        img: "/assets/img/webp/mongo.webp",
        title: "MongoDB",
    },
    {
        img: "/assets/img/webp/azure.webp",
        title: "Azure"
    },
    {
        img: "/assets/img/webp/aws.webp",
        title: "Amazon Web Services (AWS)"
    },
]

const serviceData22 = [
    {
        img: "/assets/img/webp/lara.webp",
        title: "Laravel"
    },
    {
        img: "/assets/img/webp/php.webp",
        title: "PHP"
    },
    {
        img: "/assets/img/webp/mysql.webp",
        title: "MySQL"
    },
    {
        img: "/assets/img/webp/mongo.webp",
        title: "MongoDB",
    },
    {
        img: "/assets/img/webp/azure.webp",
        title: "Azure"
    },
    {
        img: "/assets/img/webp/aws.webp",
        title: "Amazon Web Services (AWS)"
    },
]

const serviceData32 = [
    {
        img: "/assets/img/webp/lara.webp",
        title: "Laravel"
    },
    {
        img: "/assets/img/webp/php.webp",
        title: "PHP"
    },
    {
        img: "/assets/img/webp/mysql.webp",
        title: "MySQL"
    },
    {
        img: "/assets/img/webp/mongo.webp",
        title: "MongoDB",
    },
    {
        img: "/assets/img/webp/azure.webp",
        title: "Azure"
    },
    {
        img: "/assets/img/webp/aws.webp",
        title: "Amazon Web Services (AWS)"
    },
]

const serviceData3 = [
    {
        img: "https://via.placeholder.com/800x560",
        title: "Real time analytics",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"
    },
    {
        img: "https://via.placeholder.com/800x560",
        title: "Google advertising",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"
    },
    {
        img: "https://via.placeholder.com/800x560",
        title: "Pixel perfect design",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"
    }
]

const serviceData4 = [
    {
        img: "/assets/img/webp/angularr.webp",
        title: "Angular Framework"
    },
    {
        img: "/assets/img/webp/nodejs.webp",
        title: "NodeJs"
    },
    {
        img: "/assets/img/webp/mysql.webp",
        title: "MySQL"
    },
    {
        img: "/assets/img/webp/mongo.webp",
        title: "MongoDB",
    },
    {
        img: "/assets/img/webp/azure.webp",
        title: "Azure"
    },
    {
        img: "/assets/img/webp/aws.webp",
        title: "Amazon Web Services (AWS)"
    },
]

const serviceData5 = [
    {
        title: "Developing strategy",
        icon: "feather-arrow-right",
        content: "Lorem ipsum is simply dummy text of the printing typesetting lorem ipsum been dummy text.",
        link: "/page/about-me",
        linkTitle: "More Info"
    },
    {
        title: "Blazing performance",
        icon: "feather-arrow-right",
        content: "Lorem ipsum is simply dummy text of the printing typesetting lorem ipsum been dummy text.",
        link: "/page/about-me",
        linkTitle: "More Info"
    },
    {
        title: "Customer satisfaction",
        icon: "feather-arrow-right",
        content: "Lorem ipsum is simply dummy text of the printing typesetting lorem ipsum been dummy text.",
        link: "/page/about-me",
        linkTitle: "More Info"
    }
]

const serviceData6 = [
    {
        img: "https://via.placeholder.com/337x337",
        title: "Daily healthy tips",
        content: "Lorem dolor amet consectetur adipiscing eiusmod tempor"
    },
    {
        img: "https://via.placeholder.com/337x337",
        title: "Professional trainers",
        content: "Lorem dolor amet consectetur adipiscing eiusmod tempor"
    },
    {
        img: "https://via.placeholder.com/337x337",
        title: "Physical activity",
        content: "Lorem dolor amet consectetur adipiscing eiusmod tempor"
    },
    {
        img: "https://via.placeholder.com/337x337",
        title: "Natural environment",
        content: "Lorem dolor amet consectetur adipiscing eiusmod tempor"
    },
]

const serviceData7 = [
    {
        img: "https://via.placeholder.com/600x625",
        title: "Cardio",
        content: "Lorem ipsum dolor amet consectetur adipiscing eiusmod tempor.",
        btnName: "Join classes",
        btnLink: "#"
    },
    {
        img: "https://via.placeholder.com/600x625",
        title: "Crossfit",
        content: "Lorem ipsum dolor amet consectetur adipiscing eiusmod tempor.",
        btnName: "Join classes",
        btnLink: "#"
    },
    {
        img: "https://via.placeholder.com/600x625",
        title: "Sculpturing",
        content: "Lorem ipsum dolor amet consectetur adipiscing eiusmod tempor.",
        btnName: "Join classes",
        btnLink: "#"
    },
    {
        img: "https://via.placeholder.com/600x625",
        title: "Cycling",
        content: "Lorem ipsum dolor amet consectetur adipiscing eiusmod tempor.",
        btnName: "Join classes",
        btnLink: "#"
    },
]

export { serviceData1, serviceData2, serviceData3, serviceData4, serviceData5, serviceData6, serviceData7, serviceData22, serviceData32 }