import React from 'react'

// Libraries
import { Col, Container, Navbar, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Parallax } from "react-scroll-parallax";
import { m } from 'framer-motion'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from 'swiper';

// Components
import { TiltBox, TextAnime } from '../../Components/FancyText/FancyText'
import Clients from '../../Components/Clients/Clients'
import Header, { HeaderNav, Menu, SearchBar, Topbar } from '../../Components/Header/Header'
import { fadeIn, fadeInLeft, zoomIn } from '../../Functions/GlobalAnimations'
import Lists from '../../Components/Lists/Lists'
import Accordions from '../../Components/Accordion/Accordion'
import BlogClean from '../../Components/Blogs/BlogClean'
import PricingTable04 from '../../Components/PricingTable/PricingTable04';
import TextBox from '../../Components/TextBox/TextBox'
import CustomModal from '../../Components/CustomModal'
import Buttons from '../../Components/Button/Buttons'
import Team from '../../Components/Team/Team'
import Counter from '../../Components/Counters/Counter'
import FooterStyle03 from '../../Components/Footers/FooterStyle03'
import SideButtons from "../../Components/SideButtons";
import Tab07 from '../../Components/Tab/Tab07';
import TestimonialsCarousel05 from '../../Components/TestimonialCarousel/TestimonialsCarousel05';

// Data
import { CounterData02 } from '../../Components/Counters/CounterData'
import { TextSliderData01, TextSliderData02, TextSliderData03 } from '../../Components/TextSlider/TextSliderData'
import { TabData07 } from '../../Components/Tab/TabData';
import { TeamData03 } from '../../Components/Team/TeamData'
import TextSlider02 from '../../Components/TextSlider/TextSlider02'
import { TextBoxData01 } from '../../Components/TextBox/TextBoxData'
import { blogData } from "../../Components/Blogs/BlogData";
import InViewPort from '../../Components/InViewPort';
import { pricingTable04 } from '../../Components/PricingTable/PricingTableData';
import { TestimonialsCarouselData9 } from '../../Components/TestimonialCarousel/TestimonialsCarouselData';
import { ListData01, ListData02, ListData03, ListData04, ListData05, ListData06, ListData07, ListData08 } from '../../Components/Lists/ListsData'
import { ClientData05 } from "../../Components/Clients/ClientsData";

export const listDataFaq = [
    {
        title: "Most Popular Topics",
        content: [
            "Do you offer services beyond software development?",
            "What kind of projects do you develop?",
            "Do you provide after support?"
        ]
    },
    {
        title: "Web App Development",
        content: [
            "How much does it cost for a project?",
            "What are the technologies your using?",
            "Can you maintain our website?"
        ]
    },
    {
        title: "Mobile App Development",
        content: [
            "What programming languages are commonly used for Mobile App Development?",
            "What are some common Challenges in Mobile App Development?",
            "How do I Choose the Right Mobile App Development Company in Canada?"
        ]
    },
    {
        title: "Angular App Development",
        content: [
            "What are the advantages of outsourcing AngularJS development?",
            "Can an AngularJS development company customize solutions according to my business needs?",
            "Can I use Angular for mobile app development?"
        ]
    },
    {
        title: "CRM App Development",
        content: [
            "What is Custom CRM Software Development?",
            "How long does it take to develop a CRM system?",
            "Are there hidden costs in CRM development?"
        ]
    },
    {
        title: "Desktop App Development",
        content: [
            "Will I ever have to pay any fees like renewals?",
            "Skrill has been reinstated as a payment option",
            "Guidelines for item comments and ratings"
        ]
    }
]

const SwiperData = [
    {
        "img": "/assets/img/webp/home1.webp",
        "title": "Business Solutions with Software",
        "subtitle": "Efficient, Innovative, Cost-effective."
    },
    {
        "img": "/assets/img/webp/home2.webp",
        "title": "Maximize Online Efficiency",
        "subtitle": "Dynamic Digital Innovation."
    },
    {
        "img": "/assets/img/webp/home3.webp",
        "title": "Tech-Powered Business Transformation",
        "subtitle": "Creative and Efficient Solutions."
    }

]

const listData = [
    {
        icon: "fas fa-check",
        content: "Gain a competitive edge with data-driven insights that fuel informed decision-making.",
    },
    {
        icon: "fas fa-check",
        content: "Experience the power of innovation with our ever-evolving software that adapts to the changing business landscape.",
    },
    {
        icon: "fas fa-check",
        content: "Empower your team with intuitive tools that drive productivity and collaboration.",
    },
]

const AccordionData = [
    {
        title: "Innovative Solutions",
        content: "Providing innovative web development solutions to address a variety of business needs."
    },
    {
        title: "Custom Development",
        content: "Offering custom web development services tailored to unique client requirements."
    },
    {
        title: "E-commerce Expertise",
        content: "Specializing in e-commerce solutions to boost online businesses and enhance user experiences."
    }
]

// Filter the blog data category wise
const blogCleanData = blogData.filter((item) => item.blogType === "clean").filter(item => item.category.includes("consulting"));

const ConsultingPage = (props) => {
    return (
        <div style={props.style}>
            <SideButtons />
            {/* Header Start */}
            <Header topSpace={{ md: true }} type="reverse-scroll border-b border-[#ffffff1a]">
                <Topbar className="header-with-topbar bg-lightgray border-bottom pl-[35px] pr-[50px] sm:hidden lg:pr-[50px] md:pl-[15px] md:pr-[30px]">
                    <Container fluid>
                        <Row className="flex items-center justify-between">
                            <Col sm="auto">
                                {/* <p className="text-md">Professional services&nbsp; */}
                                <Link to="/contact-us" className="text-darkgray font-medium underline hover:text-[#0000ff]">Get started now?</Link>
                                {/* </p> */}
                            </Col>
                            <Col sm="auto" className="!pr-0">
                                <div className="flex text-sm leading-[30px]">
                                    <span className="flex items-center border-l py-[9px] px-[18px]">
                                        <a href="tel:+917010662317" className="flex items-center text-black">
                                            <img src="/assets/img/whatsapp.jpg" alt="WhatsApp Icon" className="mr-[10px] text-md text-darkgray" />
                                            +91-70106 62317
                                        </a>
                                    </span>

                                    <span className="flex items-center border-l py-[9px] px-[18px]">
                                        <a href="tel:+917010662317" className="flex items-center text-black">
                                            <i className="feather-phone-call mr-[10px] text-md text-darkgray"></i> +91-70106 62317
                                        </a>
                                    </span>

                                    <span className="flex items-center border-l py-[9px] pl-[18px]">
                                        <a href="mailto:info@hasotech.com" className="flex items-center text-black">
                                            <i className="feather-mail mr-[10px] text-md text-darkgray"></i> info@hasotech.com
                                        </a>
                                    </span>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </Topbar>
                <HeaderNav fluid="fluid" theme="light" bg="white" menu="light" className="px-[35px] py-[0px] md:px-0" containerClass="sm:px-0">
                    <Col className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0">
                        <Link aria-label="header logo" className="flex items-center" to="/">
                            <Navbar.Brand className="inline-block p-0 m-0">
                                <img className="default-logo" width="150" height="50" loading="lazy" src='/assets/img/logo-mob.png' data-rjs='/assets/img/logo-mob.png' alt='logo' />
                                <img className="alt-logo" width="150" height="50" loading="lazy" src='/assets/img/logo-mob.png' data-rjs='/assets/img/logo-mob.png' alt='logo' />
                                <img className="mobile-logo" width="150" height="50" loading="lazy" src='/assets/img/logo-mob.png' data-rjs='/assets/img/logo-mob.png' alt='logo' />
                            </Navbar.Brand>
                        </Link>
                    </Col>
                    <div className="col-auto hidden order-last md:block">
                        <Navbar.Toggle className="md:ml-[10px] sm:ml-0">
                            <span className="navbar-toggler-line"></span>
                            <span className="navbar-toggler-line"></span>
                            <span className="navbar-toggler-line"></span>
                            <span className="navbar-toggler-line"></span>
                        </Navbar.Toggle>
                    </div>
                    <Navbar.Collapse className="col-auto px-0 justify-end">
                        <Menu {...props} />
                    </Navbar.Collapse>
                    <Col className="col-auto text-right pe-0">
                        <SearchBar className="pr-0" />

                    </Col>
                </HeaderNav>
            </Header>
            {/* Header End */}

            {/* Section Start */}
            <section className="full-screen md:h-[600px] sm:h-[500px]">
                <Swiper
                    className="slider-nav-dark white-move swiper-pagination-03 swiper-pagination-light swiper-pagination-large h-full"
                    modules={[Pagination, Autoplay]}
                    slidesPerView={1}
                    spaceBetween={0}
                    loop={true}
                    autoplay={{ delay: 4500, disableOnInteraction: false }}
                    pagination={{ dynamicBullets: false, clickable: true }}
                >
                    {
                        SwiperData.map((item, i) => {
                            return (
                                <SwiperSlide key={i} style={{ backgroundImage: `url(${item.img})` }} className="bg-no-repeat	bg-cover	overflow-hidden relative bg-center">
                                    <div className="absolute h-full w-full top-0 left-0 bg-transparent-gradient-smoky-black"></div>
                                    <Container className="h-full">
                                        <Row className="h-full">
                                            <Col xl={6} lg={7} sm={8} xs={12} className="h-full text-white font-serif justify-center flex-col relative flex">
                                                <p className="mb-[40px] font-light text-xmd opacity-70 xs:mb-[20px]">{item.subtitle}</p>
                                                <h2 className="mb-[55px] font-semibold xs:mb-[35px]">{item.title}</h2>
                                                <div className="inline-block">
                                                    <Buttons aria-label="Contact Us" ariaLabel="button" href="/contact-us" className="btn-fill mx-[10px] font-medium font-serif rounded-none uppercase" themeColor="#0038e3" color="#fff" size="lg" title="Contact Us" />
                                                    {/* <Buttons ariaLabel="button" href="/contact-us" className="mx-[10px] font-medium font-serif uppercase hover:text-darkgray btn-expand" size="xl" color="#ffffff" themeColor="#0000ff" to="/elements/accordions" title="Contact us" /> */}
                                                    <Buttons to="/our-process" size="md" className="!pt-0 px-0 pb-[2px] relative border-b-[2px] bg-transparent m-auto after:h-[2px] after:bg-white font-serif text-[13px] tracking-[0.5px] hover:text-white uppercase btn-link md:mb-[15px] mb-[15px]" color="#fff" title="HOW WE WORK" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </section>
            {/* Section End */}

            {/* About section Start */}
            <section className="py-[100px] overflow-hidden cover-background lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]" style={{ backgroundColor: "#ffffff" }}>
                <Container>
                    <Row className="items-center justify-center">
                        <m.div className="col-lg-6 col-md-10 md:mb-20 consulting-about" {...fadeIn}>
                            <h2 className="heading-4 font-serif font-medium text-darkgray tracking-[-1px] mb-16 w-[80%] lg:w-[90%] md:mb-12 xs:mb-16 xs:w-full">We provide solutions to <span className="text-[#0000ff] font-semibold">grow your business</span></h2>
                            <p className="w-[70%] mb-[40px] lg:w-[90%] md:mb-[45px] xs:mb-[25px]">Empower your business with innovative tech solutions. Let us turn challenges into triumphs together.</p>
                            <Lists theme="list-style-02" data={listData} />
                            <div className="mt-[50px] md:mt-[25px] xs:mt-[35px] landscape:md:mt-[40px]">
                                <Buttons ariaLabel="About Company" href="/about-us" className="btn-fill mx-[10px] font-medium font-serif rounded-none uppercase" themeColor="#0038e3" color="#fff" size="lg" title="About Company" />
                                {/* <Buttons to="/about-us" className="mr-[35px] btn-fill btn-fancy rounded-none font-medium font-serif uppercase md:mb-[15px] xs:mb-0" themeColor="#0000ff" color="#fff" size="sm" title="About company" /> */}                                <Buttons to="/our-services" className="font-medium pt-0 font-serif uppercase btn-link after:h-[2px] md:text-md md:mb-[15px] after:bg-darkgray hover:text-blue xs:mb-0" size="xl" color="#0000ff" title="Discover tour" />
                            </div>
                        </m.div>
                        <m.div className="col-lg-5 offset-lg-1 col-md-10" {...fadeIn}>
                            <div className="relative">
                                <div className="absolute h-full w-full top-0 left-0 opacity-20"></div>
                                <img className="w-full" alt="" src="/assets/img/webp/hasohome.webp" width="500px" height="607.66px" />
                            </div>
                        </m.div>
                    </Row>
                </Container>
            </section>
            {/* About section End */}

            {/* Section Start */}
            <m.section className="py-[130px] border-t overflow-hidden lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]" {...fadeIn}>
                <Container fluid className="px-0">
                    <Row className="justify-center">
                        <Col md={12} className="text-center mb-[5%]">
                            {/* <h4 className="mb-[7%] font-serif">
                                    <span className="text-darkgray mr-[18px] xs:mr-0">We create the</span>
                                    <TextAnime duration={3000} className="font-semibold xxs:text-center" color="#232323" animation="rotate" data={["perfect", "design", "code", "delivered"]} />
                                </h4> */}
                            <Row className="row-cols-1 justify-center">
                                <Col xl={8} lg={10} className="text-center">
                                    {/* <div className="w-[40px] h-[2px] bg-gradient-to-tr from-[#0000ff] via-[#0000ff] to-[#0000ff] transform rotate-90 my-[30px] inline-block"></div> */}
                                    <h3 className="font-serif font-medium text-darkgray -tracking-[1px]">Top Rated Software Development Company Providing <span className="text-gradient bg-gradient-to-tr from-[#0000ff] to-[#0000ff] font-semibold">strategically</span> driven <span className="text-gradient bg-gradient-to-tr from-[#0000ff] to-[#0000ff] font-semibold">innovative</span> Solutions</h3>
                                </Col>
                            </Row>
                            {/* <h2 className="font-serif text-darkgray font-medium">Top Rated Software Development Company</h2> */}

                        </Col>
                    </Row>
                    <TextSlider02
                        data={TextSliderData02}
                        carousalOption={{
                            slidesPerView: 1,
                            spaceBetween: 60,
                            loop: true,
                            centeredSlides: true,
                            autoplay: { delay: 4500, disableOnInteraction: false },
                            breakpoints: { 991: { slidesPerView: 2 }, 767: { slidesPerView: 1 } }
                        }}
                        animation={fadeIn}
                    />
                </Container>
            </m.section>
            {/* Section End */}

            {/* Section Start */}
            <m.section className="bg-[#F7F7F7] pt-[80px] lg:pt-[90px] md:pt-[75px] xs:pt-[50px] overflow-hidden" {...fadeIn}>
                <Container>
                    <Row className="items-center">
                        <Col xl={5} lg={6} className="pb-12 lg:pb-28 sm:pb-[50px]">
                            <div className="text-xmd mb-[20px] font-serif">Explore Innovative Web Solutions</div>
                            <h5 className="font-serif text-darkgray font-medium mb-[5.5rem]">Passionate Developers at Your Service!</h5>
                            <Row>
                                <Col>
                                    <div className="flex">
                                        <div className="mr-[35px]">
                                            <i className="line-icon-Idea-5 text-[50px] text-fastblue"></i>
                                        </div>
                                        <div className="feature-box-content">
                                            <span className="text-fastblue block mb-[10px] font-serif font-medium">Young Innovators at Work</span>
                                            <p className="w-[95%]">
                                                Meet our team of vibrant young developers, where passion meets innovation. We're a group of tech enthusiasts dedicated to crafting cutting-edge solutions and pushing the boundaries of web and app development. Join us on our journey to redefine digital experiences!
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="col-12"><div className="h-[1px] bg-[#ededed] my-[40px] w-full xs:my-[30px]"></div></Col>
                                <Col>
                                    <div className="flex">
                                        <div className="mr-[35px]">
                                            <i className="line-icon-Medal-2 text-[50px] text-fastblue"></i>
                                        </div>
                                        <div className="feature-box-content">
                                            <span className="text-fastblue block mb-[10px] font-serif font-medium">Strategic Solutions for Your Business</span>
                                            <p className="w-[95%]">
                                                Unlock tailored web and app solutions designed to address your business challenges. Our team of dedicated young developers is committed to creating innovative strategies that propel your business forward. Experience the power of technology solving real-world problems.
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6} xl={{ offset: 1 }} className="align-self-end text-center text-xl-start">
                            <img className="md:mx-auto" loading="lazy" src="/assets/img/webp/lapguy.webp" width="504" height="666" alt="web-developers" />
                        </Col>
                    </Row>

                </Container>
            </m.section>
            {/* Section End */}

            {/* Parallax Start */}
            <section className="py-[100px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]">
                <Container>
                    {/* <Row className="justify-center">
                            <Col md={12} className="text-center mb-[7%]">
                                <h6 className="font-serif text-darkgray font-medium">Parallax scrolling style 01</h6>
                            </Col>
                        </Row> */}
                    <Row className="items-center">
                        <Col xl={7} lg={6} className="relative">
                            <div className="relative">
                                <Parallax className="lg-no-parallax w-[70%] mb-16 lg:mb-0" speed={0}> <img alt="" src="/assets/img/webp/phnnew.webp" className="w-full" width="385.34" height="565.34" /></Parallax>
                                <Parallax className="lg-no-parallax flex justify-center items-center w-[55%] bg-no-repeat absolute bottom-0 right-[15px] lg:ml-auto lg:!top-[130px]" speed={20}>
                                    <img className="w-full" alt="" src="/assets/img/webp/apppp.webp" width="317" height="477" />
                                </Parallax>
                            </div>
                        </Col>
                        <Col xl={{ span: 4, offset: 1 }} lg={{ span: 5, offset: 1 }} className="md:mt-[15%] xs:mt-[18%]">
                            <span className="font-serif block mb-[30px] font-semibold tracking-[2px] text-[#262b35] xs:mb-[20px]">CUSTOM APPS, NO STRINGS ATTACHED!</span>
                            <h4 className="font-serif uppercase mb-[35px] font-bold tracking-[-1px] text-[#0000ff] md:mb-[35px]">Break Free from Subscription Shackles with Our Tailored Apps.</h4>
                            <p className="mb-[25px] md:mb-[20px] sm:w-[90%] sm:mb-[15px] xs:w-full">Say goodbye to the never-ending cycle of subscription payments. Embrace a new era of business empowerment with our bespoke applications—crafted to match and surpass the capabilities of popular SaaS offerings, putting you in control of your technology expenses.</p>
                            <Buttons ariaLabel="Discover Services" href="/our-services" className="btn-fill mx-[10px] font-medium font-serif rounded-none uppercase" themeColor="#0038e3" color="#fff" size="lg" title="Discover Services" />
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* Parallax End */}

            {/* Section Start */}
            <div className="py-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px] md:flex md:items-center overflow-hidden relative">
                <Parallax className="lg-no-parallax bg-cover absolute top-[0px] left-0 md:-top-[30px] w-full h-[100vh] xs:bg-[center_center]" translateY={[-40, 40]} style={{ backgroundColor: "#0000ff" }}></Parallax>
                <div className="absolute h-full w-full opacity-80 top-0 left-0 bg-gradient-to-tr from-[#0000ff] to-[#0000ff]"></div>
                <Container className="relative">
                    <Row className="justify-center">
                        <Col xl={7} lg={8} sm={10} className="text-center text-white font-serif">
                            <m.div {...fadeIn}>
                                <h2 className="heading-4 font-semibold mb-[45px] sm:mb-[25px]">Ignite Your Web Presence with Innovative Ideas and Insights</h2>
                                <span className="uppercase tracking-[2px]">Unleash Unlimited Power and Customization for Your Digital Success</span>

                            </m.div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Section End */}

            <section className="py-[80px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]">
                <Container>
                    <Row className='justify-center'>
                        <Col md={6} className="font-serif text-center mb-20 sm:mb-12">
                            <h2 className="text-darkgray font-semibold -tracking-[1px]">3rd Party API Integration <span className="text-gradient bg-gradient-to-tr from-[#0000ff] to-[#0000ff] font-semibold">Services</span></h2>
                        </Col>
                    </Row>
                    <Clients
                        grid="row row-cols-1 row-cols-lg-4 row-cols-sm-2"
                        theme="client-logo-style-05"
                        data={ClientData05}
                        animation={fadeIn}
                    />
                </Container>
            </section>


            {/* Section Start */}
            <m.section className="ourservice-pricingtable py-[100px] lg:py-[90px] sm:py-[75px] xs:py-[50px] bg-lightgray" {...fadeIn}>
                <Container>
                    <Row className="justify-center">
                        <Col md={6} className="font-serif text-center mb-20 sm:mb-12">
                            <span className="mb-[10px] inline-block uppercase font-medium text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e] tracking-[1px]">Simple Pricing</span>
                            <h2 className="text-darkgray font-semibold -tracking-[1px]">No Development Cost, Pay As You Go!</h2>
                        </Col>
                    </Row>
                    <PricingTable04 grid="row-cols-1 row-cols-lg-3 gap-y-[30px] justify-center items-center" theme="pricing-table-style-04 col-12 col-lg-4 col-md-7" className="" data={pricingTable04} />
                </Container>
            </m.section>
            {/* Section End */}

            <m.section className="py-[100px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]" {...fadeIn}>
                <Container>
                    <Row>
                        <Col className="text-center">
                            <h2 className="text-darkgray font-light">Services which can be provided in a <span className="inline-block font-semibold border-b-2 border-fastblue">Week</span></h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4} md={6} className="px-[15px] mb-[15px]">
                            <div className="p-[20px] sm:p-[30px]">
                                <img className="w-full" alt="" src="/assets/img/webp/week.webp" width="500px" height="607.66px" />
                            </div>
                        </Col>
                        <Col lg={8} md={6} className="px-[30px] mb-[15px] d-flex align-items-center">
                            <div className="p-[20px] sm:p-[30px]">
                                <Lists theme="list-style-05" data={ListData05} animation={fadeIn} />
                            </div>
                        </Col>

                    </Row>
                </Container>
            </m.section>


            {/* Lazy Load HTML */}
            <InViewPort>
                {/* Section Start */}
                <section className="py-[130px] bg-[#F7F7F7] overflow-hidden lg:py-[90px] md:py-[75px] sm:py-[50px]" >
                    <Container>
                        <m.div className="row justify-center" {...fadeIn}>
                            <Col xl={6} lg={7} sm={8} className="text-center font-medium mb-12 font-serif xs:mb-0">
                                <span className="text-[#0000ff] uppercase block mb-[15px]">Thoughtful experiences</span>
                                <h2 className="heading-5 text-darkgray inline-block">Software solutions that move your business forward</h2>
                            </Col>
                        </m.div>
                        <TextBox grid="row-cols-1 row-cols-md-3 row-cols-sm-2 justify-center gap-y-10" theme="text-box-style-01" data={TextBoxData01} animation={fadeIn} ani />
                    </Container>
                </section>
                {/* Section End */}

                <m.section className="py-[80px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]" {...fadeIn}>
                    <h2 className="font-serif text-gray-900 text-center font-medium mb-24 xs:mb-16"> Their Experience, Our Delight!</h2>
                    <Container>
                        <Row className="justify-center">
                            <Col xs={12} xl={7} lg={7} md={9}>
                                <TestimonialsCarousel05
                                    data={TestimonialsCarouselData9}
                                    className="black-move"
                                    carouselOption={{
                                        slidesPerView: 1,
                                        loop: true,
                                        navigation: false,
                                        autoplay: { delay: 2500, disableOnInteraction: false },
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>
                </m.section>

                {/* Section Start */}
                <section className="py-[130px] overflow-hidden lg:py-[90px] bg-[#0000ff]">
                    <Container>
                        <Counter
                            theme="counter-style-02"
                            grid="row row-cols-1 row-cols-md-4 row-cols-sm-2 items-center justify-center text-center gap-y-10"
                            className="heading-4 text-white font-serif"
                            as="h2"
                            data={CounterData02}
                            duration={2}
                        />
                    </Container>
                </section>
                {/* Section End */}

                {/* Section Start */}
                <section className="py-[80px] lg:py-[90px] md:py-[75px] sm:py-[50px]">
                    <Container fluid className="px-[12%] xl:px-[10%] lg:px-[3%]">
                        <Row>
                            <Col className="col-12 text-center mb-28">
                                <span className="block font-serif mb-[5px]">Most common questions</span>
                                <h2 className="font-serif text-darkgray font-semibold mb-0">Frequently asked questions</h2>
                            </Col>
                        </Row>
                        <Row className="gap-y-[30px]">
                            {listDataFaq.map((item, i) => {
                                return (
                                    <m.div className="col-12 col-lg-4 col-sm-6" key={i} {...fadeIn}>
                                        <div className="faqs-page-list bg-white rounded box-shadow-small p-16 lg:p-12 h-full">
                                            <span className="font-serif text-xmd text-darkgray font-medium block mb-[15px]">{item.title}</span>
                                            <ul className="p-0 list-style text-start mb-[15px]">
                                                {item.content && item.content.map((item, i) => {
                                                    return (
                                                        <li key={i}><Link to="/faq" className="hover:text-fastblue">{item}</Link></li>
                                                    )
                                                })}
                                            </ul>
                                            <Buttons ariaLabel="Agency info" href="/faq" className="font-medium font-serif uppercase btn-link !tracking-[.5px] after:h-[2px] after:bg-fastblue hover:text-fastblue" size="md" color="#0038e3" title="See all articles" />
                                        </div>
                                    </m.div>
                                )
                            })}
                        </Row>
                    </Container>
                </section>
                {/* Section End */}

                {/* Section Start */}
                <section className="py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px] bg-lightgray">
                    <Container>
                        <Row className="row-cols-1 row-cols-lg-3 row-cols-sm-1 md:gap-y-[50px] sm:gap-y-[30px] text-center">
                            <Col>
                                <i className="line-icon-Geo2-Love text-gradient bg-fastblue text-[32px] mb-[30px] sm:mb-[10px] inline-block md:mb-[15px]"></i>
                                <div className="text-darkgray uppercase text-sm font-medium font-serif mb-[10px]">HasoTechnology</div>
                                <p className="w-[75%] lg:w-[90%] md:w-[60%] sm:w-[75%] xs:w-full mx-auto">161/1-1, Kamarajar Salai,
                                    Madurai-625009,
                                    Tamil Nadu, India.</p>
                            </Col>
                            <Col>
                                <i className="line-icon-Headset text-gradient bg-fastblue text-[32px] mb-[30px] sm:mb-[10px] inline-block md:mb-[15px]"></i>
                                <div className="text-darkgray uppercase text-sm font-medium font-serif mb-[10px]">Let's Talk</div>
                                <p className="w-[70%] lg:w-full mx-auto">Phone: +9170106 62317</p>
                            </Col>
                            <Col>
                                <i className="line-icon-Mail-Read text-gradient bg-fastblue text-[32px] mb-[30px] sm:mb-[10px] inline-block md:mb-[15px]"></i>
                                <div className="text-darkgray uppercase text-sm font-medium font-serif mb-[10px]">E-MAIL US</div>
                                <p className="w-[70%] lg:w-full mx-auto">
                                    <a aria-label="mail" href="mailto:info@hasotech.com" className="hover:text-basecolor">info@hasotech.com</a><br />
                                    <a aria-label="mail" href="mailto:hr@hasotech.com" className="hover:text-basecolor">hr@hasotech.com</a>
                                </p>
                            </Col>
                            {/* <Col>
              <i className="line-icon-Information text-gradient bg-fastblue text-[32px] mb-[30px] sm:mb-[10px] inline-block md:mb-[15px]"></i>
              <div className="text-darkgray uppercase text-sm font-medium font-serif mb-[10px]">CUSTOMER SERVICES</div>
              <p className="w-[75%] lg:w-full md:w-[60%] sm:w-[75%] mx-auto">Lorem ipsum is simply dummy text printing</p>
            </Col> */}
                        </Row>
                    </Container>
                </section>
                {/* Section End */}

                {/* Footer Start */}
                <FooterStyle03 theme="dark" className="text-[#828282] bg-[#0b0b26] consulting-footer" />
                {/* Footer End */}
            </InViewPort>
        </div >
    )
}

export default ConsultingPage